/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import parsePhoneNumber from 'libphonenumber-js';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import Input from '@/components/Input/Input';
import PhoneNumberInput from '@/components/PhoneInput';
import { updateCustomer } from '@/services/customerApi';
import { device } from '@/constants/breakpoints';

const customerTypes = [
  { id: 1, name: 'Individual', value: 'individual' },
  { id: 2, name: 'Business', value: 'business' }
];

function EditCustomer({ showModal, setShowModal, selectedcustomer }) {
  const queryClient = useQueryClient();
  const [customerType, setCustomerType] = useState(null);

  const schema = yup
    .object({
      companyName: (() => {
        let validation = yup.string();
        if (customerType === 'business') {
          validation = validation
            .min(3, 'Company name must be at least 3 characters long')
            .required('Company name is required');
        }
        return validation;
      })(),
      name: yup
        .string()
        .min(3, 'Customer name must be at least 3 characters long')
        .required('Customer name is required'),
      phoneNumber: yup
        .string()
        .required("Customer's phone number is required")
        .min(14, 'Enter a valid phone number')
        .matches(/^\+[1-9]\d{1,14}$/, 'Phone number must start with a valid country code'),
      email: yup.string().email('Invalid email format').nullable()
    })
    .required();

  useEffect(() => {
    setCustomerType(selectedcustomer?.type);
  }, [selectedcustomer]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      companyName: selectedcustomer?.companyName,
      name: selectedcustomer?.name,
      phoneNumber: selectedcustomer?.phoneNumber || '',
      email: selectedcustomer?.email
    }
  });

  useEffect(() => {
    reset({
      companyName: selectedcustomer?.companyName,
      name: selectedcustomer?.name,
      phoneNumber: selectedcustomer?.phoneNumber || '',
      email: selectedcustomer?.email
    });
  }, [selectedcustomer, reset]);

  const handleUpdateCustomer = useMutation(({ id, data }) => updateCustomer(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customer'] });
      queryClient.invalidateQueries({ queryKey: ['customers'] });
      setShowModal(false);
      toast.success('Customer updated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      phoneNumber: data?.phoneNumber,
      email: data?.email ? data?.email?.trim() : null,
      type: customerType,
      name: data?.name?.trim(),
      countryCode: parsePhoneNumber(data?.phoneNumber).countryCallingCode,
      companyName: data?.companyName?.trim()
    };

    handleUpdateCustomer.mutate({ id: selectedcustomer?.id, data: formData });
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>

            <FormView>
              <Form>
                {/* ====== Company type ====== */}
                <SelectView>
                  <label htmlFor="customerType">Customer type</label>
                  <div className="options">
                    {customerTypes.map((item) => (
                      <div
                        className="option"
                        key={item?.id}
                        onClick={() => setCustomerType(item?.value)}>
                        <div className="radio-button">
                          {customerType === item?.value ? <span className="dot"></span> : null}
                        </div>
                        <p>{item?.name}</p>
                      </div>
                    ))}
                  </div>
                </SelectView>

                {/* ======= Company name ======= */}
                {customerType === 'business' && (
                  <InputView>
                    <label htmlFor="companyName">Customer’s company name</label>
                    <Input
                      type="text"
                      {...register('companyName')}
                      error={errors?.companyName?.message ? true : false}
                      placeholder="Enter company name"
                    />

                    {errors?.companyName && (
                      <span className="error-label">{errors?.companyName?.message}</span>
                    )}
                  </InputView>
                )}

                {/* ======= Customer name ======= */}
                <InputView>
                  <label htmlFor="name">Customer’s full name</label>
                  <Input
                    type="text"
                    {...register('name')}
                    error={errors?.name?.message ? true : false}
                    placeholder="Enter customer name"
                  />

                  {errors?.name && <span className="error-label">{errors?.name?.message}</span>}
                </InputView>

                {/* ======= Email address ======= */}
                <InputView>
                  <label htmlFor="email">
                    Email address <span>(optional)</span>
                  </label>
                  <Input
                    type="text"
                    {...register('email')}
                    error={errors?.email?.message ? true : false}
                    placeholder="Enter email address"
                  />

                  {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                </InputView>

                {/* Phone number */}
                <InputView>
                  <label htmlFor="name">
                    Phone No.
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <PhoneNumberInput
                        name={name}
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        error={errors?.phoneNumber?.message ? true : false}
                        className={`currency-input ${errors?.phoneNumber && dirtyFields?.phoneNumber
                          ? ' has-error'
                          : dirtyFields?.phoneNumber
                            ? 'valid'
                            : ''
                          }`}
                      />
                    )}
                  />

                  {errors?.phoneNumber && (
                    <span className="error-label">{errors?.phoneNumber?.message}</span>
                  )}
                </InputView>

                {/* ===== CTA ====== */}
                <ButtonView>
                  <button type="button" className="cta-view__save" onClick={handleSubmit(onSubmit)}>
                    {handleUpdateCustomer.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Update customer'
                    )}
                  </button>
                </ButtonView>
              </Form>
            </FormView>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default EditCustomer;

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 30px 16px;
    }
  }
`;

const FormView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const Form = styled.form`
  width: 40%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }
`;

const SelectView = styled.div`
  width: 100%;
  margin-bottom: 20px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 14px;

    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-right: 50px;

      .radio-button {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors?.primaryFocus};
        margin-right: 10px;

        .dot {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background-color: ${({ theme }) => theme.colors?.primary};
        }
      }

      p {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors?.secondary};
      }
    }
  }
`;

const InputView = styled.div`
  width: 100%;
  margin-bottom: 30px;

  label {
    font-size: 0.875rem;
    font-weight: 400;

    span {
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }

  .error-label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors?.error};
    margin-top: 5px;
  }

  .input-info-view {
    width: 100%;
    display: flex;
    margin-top: 8px;

    span {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors?.info};
    }
  }
`;

const ButtonView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
  margin-bottom: 50px;

  button {
    width: 200px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;

    @media ${device.phone} {
      width: 100%;
    }
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
