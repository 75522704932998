import PaymentAdvice from '@/components/PaymentAdvice';
import PaymentSuccessfulModal from '@/components/PaymentSuccessful';
import useAuthentication from '@/hooks/useAuthentication';
import { fetchPaymentAdvicePDF, verifyPayment } from '@/services/billsApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

function VerifyPayment() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const search = location.search;
  const trxref = new URLSearchParams(search).get('trxref');
  const { authenticated } = useAuthentication();
  const [showModal, setShowModal] = useState(false);
  const [showPaymentAdvice, setShowPaymentAdvice] = useState(false);
  const [amount, setAmount] = useState(0);
  const [recipient, setRecipient] = useState('');
  const [billData, setBillData] = useState(null);

  const handleVerifyPayment = useQuery({
    queryKey: ['bills', trxref],
    queryFn: verifyPayment,
    enabled: !billData,
    onSuccess: (data) => {
      if (authenticated) {
        // toast.success('Payment verified');
        if (data?.data?.bill?.type === 'vendor') {
          queryClient.invalidateQueries({ queryKey: ['bills'] });
          queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
          setShowPaymentAdvice(true);
          setBillData(data?.data);
          setRecipient(`${data?.data?.bill?.vendor?.name || '-'}`);
        } else {
          queryClient.invalidateQueries({ queryKey: ['invoices'] });
          queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
          setShowPaymentAdvice(true);
          setBillData(data?.data);
          setRecipient(`${data?.data?.user?.companies[0]?.name || '-'}`);
        }
      } else {
        setAmount(data?.data?.amount);
        if (data?.data?.user?.companies?.length > 0) {
          setRecipient(`${data?.data?.user?.companies[0]?.name || '-'}`);
        }

        setShowModal(true);
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  });

  // Fetch payment advice
  const fetchPaymentAdvice = useQuery({
    queryKey: ['paymentAdvice', trxref],
    queryFn: fetchPaymentAdvicePDF,
    enabled: !!billData
  });

  return (
    <VerifyView>
      {handleVerifyPayment.isLoading || fetchPaymentAdvice?.isLoading ? (
        <>
          <Oval color="#92c22c" width={50} height={50} strokeWidth={5} />
          <p>Verifying payment...</p>
        </>
      ) : null}

      <PaymentAdvice
        showModal={showPaymentAdvice}
        setShowModal={setShowPaymentAdvice}
        billData={billData}
        paymentAdvice={fetchPaymentAdvice?.data?.data?.url}
      />

      <PaymentSuccessfulModal showModal={showModal} amount={amount} recipient={recipient} />
    </VerifyView>
  );
}

export default VerifyPayment;

const VerifyView = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors?.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    margin-top: 10px;
  }
`;
