/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import SearchDropdown from '@/components/SearchDropdown';
import { fetchCustomer, fetchCustomers } from '@/services/customerApi';
import Input from '@/components/Input/Input';
import { fetchCompany, fetchUser } from '@/services/userApi';
import { device } from '@/constants/breakpoints';
import NewCustomerModal from '../components/NewCustomerForm';
import PageLoader from '@/components/PageLoader';
import PreviewInvoice from './components/PreviewInvoice';
import { fetchInvoice } from '@/services/receivablesApi';
import EditInvoiceItems from './components/EditInvoiceItems';
import WYSIWYG from '@/components/WYSIWYG';
import withAuthentication from '@/hooks/withAuthentication';
import FindCustomerModal from '../invoiceFinancing/FindCustomer';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 1, name: 'Daily', value: 'daily' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' },
  { id: 3, name: 'Quarterly', value: 'quarterly' },
  { id: 3, name: 'Yearly', value: 'yearly' }
];

function EditInvoice() {
  const navigate = useNavigate();
  const { id } = useParams();
  const message = 'Are you sure you want to go back?';
  const [customer, setCustomer] = useState({});
  const [frequency, setFrequency] = useState(billFrequency[0]);
  const [showCustomerForm, setShowCustomerForm] = useState(false);
  const [showFindCustomerModal, setShowFindCustomerModal] = useState(false);
  const [showItemsModal, setShowItemsModal] = useState(false);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceForm, setInvoiceForm] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [note, setNote] = useState('');
  const [subject, setSubject] = useState('');

  const [expires, setExpires] = useState(false);

  const schema = yup
    .object({
      recurringStartDate: (() => {
        let validation = yup.string();
        if (frequency?.value !== 'one-time') {
          validation = validation.required('Start date is required');
        }
        return validation;
      })(),
      recurringEndDate: (() => {
        let validation = yup.string();
        if (!expires && frequency?.value !== 'one-time') {
          validation = validation.required('End date is required');
        }
        return validation;
      })(),
      dueDate: yup.string().required('Due date is required'),
      billOrInvoiceNumber: yup.string().required('Invoice number is required'),
      requiresApproval: yup.boolean().required('Approval status is required')
    })
    .required();

  const invoice = useQuery({
    queryKey: ['invoice', id],
    queryFn: fetchInvoice,
    onSuccess: (data) => {
      setCustomer(data?.data?.customer);
      setFrequency(billFrequency.find((item) => item?.value === data?.data?.frequency));
      setSubject(data?.data?.subject);
      setNote(data?.data?.note || data?.data?.description);
      setInvoiceItems(
        data?.data?.items?.map((item) => ({
          id: item?.id,
          name: item?.name,
          quantity: item?.quantity,
          amount: item?.amount,
          rate: item?.rate
        }))
      );
      setInvoiceForm({
        customerId: data?.data?.customer?.id,
        billOrInvoiceNumber: data?.data?.billOrInvoiceNumber,
        requiresApproval: data?.data?.requiresApproval,
        dueDate: new Date(data?.data?.dueDate).toISOString(),
        frequency: data?.data?.frequency,
        recurringStartDate: data?.recurringStartDate
          ? new Date(data?.recurringStartDate).toISOString() || null
          : null,
        recurringEndDate: data?.recurringEndDate
          ? new Date(data?.recurringEndDate).toISOString() || null
          : null,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        note: data?.data?.note,
        subject: data?.data?.subject,
        status: data?.data?.status,
        tax: data?.data?.tax,
        discount: data?.data?.discount,
        taxType: data?.data?.taxType,
        discountType: data?.data?.discountType,
        vatBeforeDiscount: data?.data?.vatBeforeDiscount
      });
    }
  });

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Custom confirmation message for page refresh
      event.returnValue = message;
    };

    // Add the event listener for page refresh
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const customers = useQuery({
    queryKey: ['customers', { page: 1, pageLimit: 1000 }],
    queryFn: fetchCustomers,
    onSuccess: () => {},
    onError: () => {}
  });

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const company = useQuery({
    queryKey: ['company', user?.data?.data?.company?.id],
    queryFn: fetchCompany,
    enabled: !!user?.data?.data?.company?.id
  });

  const customerData = useQuery({
    queryKey: ['customer', customer?.id],
    queryFn: fetchCustomer,
    enabled: !!customer?.id
  });

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      billOrInvoiceNumber: invoice?.data?.data?.billOrInvoiceNumber,
      dueDate: new Date(invoice?.data?.data?.dueDate || Date.now())
        ?.toISOString()
        ?.substring(0, 10),
      recurringStartDate: invoice?.data?.data?.recurringStartDate
        ? new Date(invoice?.data?.data?.recurringStartDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : '',
      recurringEndDate: invoice?.data?.data?.recurringEndDate
        ? new Date(invoice?.data?.data?.recurringEndDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : ''
    }
  });

  useEffect(() => {
    reset({
      billOrInvoiceNumber: invoice?.data?.data?.billOrInvoiceNumber,
      dueDate: new Date(invoice?.data?.data?.dueDate || Date.now())
        ?.toISOString()
        ?.substring(0, 10),
      recurringStartDate: invoice?.data?.data?.recurringStartDate
        ? new Date(invoice?.data?.data?.recurringStartDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : '',
      recurringEndDate: invoice?.data?.data?.recurringEndDate
        ? new Date(invoice?.data?.data?.recurringEndDate || Date.now())
            ?.toISOString()
            ?.substring(0, 10)
        : ''
    });
    setExpires(!invoice?.data?.data?.shouldExpireRecurring);
  }, [invoice?.data?.data, reset]);

  const onAddItems = (data) => {
    if (!customer?.id) {
      toast.error('Please select a customer');
    } else {
      const form = {
        customerId: customer?.id,
        billOrInvoiceNumber: data?.billOrInvoiceNumber,
        requiresApproval: data?.requiresApproval,
        dueDate: new Date(data?.dueDate).toISOString(),
        frequency: frequency?.value,
        recurringStartDate: data?.recurringStartDate
          ? new Date(data?.recurringStartDate).toISOString() || null
          : null,
        recurringEndDate: data?.recurringEndDate
          ? new Date(data?.recurringEndDate).toISOString() || null
          : null,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        note: note || '',
        subject: subject || '',
        status: invoice?.data?.data?.status,
        tax: invoice?.data?.data?.tax,
        discount: invoice?.data?.data?.discount,
        taxType: invoice?.data?.data?.taxType,
        discountType: invoice?.data?.data?.discountType
      };

      setInvoiceForm(form);
      setShowItemsModal(true);
    }
  };

  if (customers.isFetching || user.isFetching || company.isFetching || invoice.isFetching) {
    return <PageLoader />;
  }

  return (
    <EditInvoiceStyles>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </div>
      <div className="row">
        <div className="title-view">
          <h1>Edit invoice</h1>
        </div>
        <div className="form-view">
          <form>
            {/* Vendor */}
            <div className="input-view vendor">
              <label htmlFor="vendor">Customer</label>
              <SearchDropdown
                options={customers?.data?.data?.items}
                id="id"
                name="companyName"
                setValue={setCustomer}
                value={customer}
                placeholder="Select customer"
              />
            </div>
            <div className="add-view">
              <button type="button" onClick={() => setShowFindCustomerModal(true)}>
                <PlusIcon /> Add new customer
              </button>
            </div>

            {/* Bill frequency */}
            <div className="input-view">
              <label htmlFor="vendor">Bill Frequency</label>
              <SearchDropdown
                options={billFrequency}
                id="id"
                name="name"
                setValue={setFrequency}
                value={frequency}
                placeholder="Select frequency"
              />
            </div>

            {frequency?.value !== 'one-time' && (
              <>
                {/* Frequency date */}
                <div className="date-row">
                  <div className="input-view">
                    <label htmlFor="name">Start date</label>

                    <Input
                      type="date"
                      min={new Date().toISOString().split('T')[0]}
                      {...register('recurringStartDate')}
                      error={errors?.recurringStartDate?.message}
                    />

                    {errors?.recurringStartDate && (
                      <span className="error-label">{errors?.recurringStartDate?.message}</span>
                    )}
                  </div>
                  <div className="input-view">
                    <label htmlFor="name">End date</label>

                    <Input
                      type="date"
                      min={
                        watch('recurringStartDate')
                          ? new Date(
                              new Date(watch('recurringStartDate')).getTime() + 24 * 3600 * 1000
                            )
                              .toISOString()
                              .split('T')[0]
                          : new Date().toISOString().split('T')[0]
                      }
                      {...register('recurringEndDate')}
                      error={errors?.recurringEndDate?.message}
                      disabled={!!expires}
                      className={expires ? 'disabled' : ''}
                    />

                    {errors?.recurringEndDate && (
                      <span className="error-label">{errors?.recurringEndDate?.message}</span>
                    )}
                  </div>
                </div>

                {/* Checkbox */}
                <div className="checkbox-view">
                  <input
                    type="checkbox"
                    name="expires"
                    id="expires"
                    value="false"
                    className="checkbox"
                    checked={expires}
                    onChange={() => setExpires(!expires)}
                  />
                  <label htmlFor="expires">Never expires</label>
                </div>
              </>
            )}

            {/* Bill number */}
            <div className="input-view">
              <label htmlFor="billOrInvoiceNumber">Invoice no.</label>
              <Input
                type="text"
                {...register('billOrInvoiceNumber')}
                error={errors?.billOrInvoiceNumber?.message}
                placeholder="Enter invoice number"
              />

              {errors?.billOrInvoiceNumber && (
                <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
              )}
            </div>

            {/* Due date */}
            <div className="input-view">
              <label htmlFor="name">Due date</label>
              <Input type="date" {...register('dueDate')} error={errors?.dueDate?.message} />

              {errors?.dueDate && <span className="error-label">{errors?.dueDate?.message}</span>}
            </div>

            {/* Subject */}
            <div className="input-view quill">
              <label htmlFor="description">
                Subject <span>(Optional)</span>
              </label>
              <WYSIWYG value={subject} setValue={setSubject} placeholder="Enter subject" />
              {/* <TextArea
                type="number"
                {...register('subject')}
                placeholder="Let your customer know what this Invoice is for"
              /> */}
            </div>

            {/* Description */}
            <div className="input-view quill">
              <label htmlFor="description">
                Note <span>(Optional)</span>
              </label>
              <WYSIWYG value={note} setValue={setNote} placeholder="Enter note" />
              {/* <TextArea
                type="number"
                {...register('note')}
                placeholder="Add note for your customer reference"
              /> */}
            </div>

            {/* Approval */}
            <div className="input-view">
              <label htmlFor="vendor">Will the invoice require approval?</label>
              <div className="options">
                <div className="option">
                  <input
                    type="radio"
                    name="approval"
                    id="approval"
                    value={true}
                    {...register('requiresApproval')}
                    // onChange={() => setApproval(true)}
                  />
                  <span>Yes</span>
                </div>

                <div className="option">
                  <input
                    type="radio"
                    name="approval"
                    id="approval"
                    value={false}
                    {...register('requiresApproval')}
                    // onChange={() => setApproval(false)}
                  />
                  <span>No</span>
                </div>
              </div>
              {errors?.requiresApproval && (
                <span className="error-label">{errors?.requiresApproval?.message}</span>
              )}
            </div>

            {/* ===== CTA ====== */}
            <div className="cta-view">
              <button type="button" className="cta-view__save" onClick={handleSubmit(onAddItems)}>
                Edit item(s)
              </button>
            </div>
          </form>
        </div>
      </div>

      <NewCustomerModal
        showModal={showCustomerForm}
        setShowModal={setShowCustomerForm}
        setCustomer={setCustomer}
      />

      <FindCustomerModal
        showModal={showFindCustomerModal}
        setShowModal={setShowFindCustomerModal}
        showAddNewCustomer={showCustomerForm}
        setShowAddNewCustomer={setShowCustomerForm}
        setCustomer={setCustomer}
      />

      <EditInvoiceItems
        showModal={showItemsModal}
        setShowModal={setShowItemsModal}
        invoiceItems={invoiceItems}
        setInvoiceItems={setInvoiceItems}
        invoiceForm={invoiceForm}
        setShowPreview={setShowPreview}
        setPreviewData={setPreviewData}
        invoiceId={id}
        user={user?.data?.data}
      />

      {/* Preview Modal */}
      <PreviewInvoice
        showModal={showPreview}
        setShowModal={setShowPreview}
        company={company?.data?.data}
        user={user?.data?.data}
        customer={customerData?.data?.data}
        previewData={previewData}
      />
    </EditInvoiceStyles>
  );
}

export default withAuthentication(EditInvoice);

const EditInvoiceStyles = styled.div`
  width: 1300px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-top: 100px;

  @media ${device.phone} {
    margin-top: 30px;
    width: 100%;
    padding: 0px 16px;
  }

  .back-view {
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;

    @media ${device.phone} {
      left: 16px;
    }
  }

  .row {
    width: 60%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 60px;
    }

    .title-view {
      width: 40%;
      padding-right: 60px;

      @media ${device.phone} {
        padding-right: 0px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      h1 {
        font-size: 1.5rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors?.secondary};

        @media ${device.phone} {
          text-align: center;
        }
      }

      p {
        font-size: 1rem;
        line-height: 19px;
        margin-top: 20px;

        @media ${device.phone} {
          text-align: center;
          max-width: 80%;
        }
      }
    }

    .indicator-view {
    }

    .form-view {
      width: 60%;
      padding-left: 80px;

      @media ${device.phone} {
        width: 100%;
        padding-left: 0px;
        margin-top: 30px;
      }

      form {
        width: 100%;
        max-width: 460px;

        .input-view {
          width: 100%;
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          input {
            width: 100%;
          }

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .error-label {
            font-size: 0.875rem;
            color: ${({ theme }) => theme.colors?.error};
            margin-top: 5px;
          }

          .input-info-view {
            width: 100%;
            display: flex;
            margin-top: 8px;

            span {
              font-size: 0.875rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 14px;

            .option {
              width: 40%;
              display: flex;
              align-items: center;

              input {
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 10px;
                accent-color: ${({ theme }) => theme.colors?.secondary};
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                cursor: pointer;
                color: ${({ theme }) => theme.colors?.primary};
              }

              span {
                font-size: 1rem;
                font-weight: 400;
                margin-bottom: 0px;
              }
            }
          }
        }

        .quill {
          margin-bottom: 10px;
        }

        .checkbox-view {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          width: 100%;
          margin-bottom: 30px;
          margin-top: 10px;

          .checkbox {
            margin-right: 5px;
            accent-color: ${({ theme }) => theme.colors?.secondary};
            cursor: pointer;
            width: 16px;
            height: 16px;
          }

          label {
            cursor: pointer;
          }
        }

        .date-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          .input-view {
            width: 48%;
            margin-bottom: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            input {
              width: 100%;
            }

            .disabled {
              background-color: ${({ theme }) => theme.colors?.layer2};

              &::placeholder {
                color: ${({ theme }) => theme.colors?.info};
              }
            }
          }
        }

        .vendor {
          margin-bottom: 10px;
        }

        .add-view {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;

          button {
            color: ${({ theme }) => theme.colors?.secondary};
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-right: 10px;
            }
          }
        }

        .select-view {
          width: 100%;
          margin-bottom: 20px;

          label {
            font-size: 0.875rem;
            font-weight: 400;

            span {
              font-size: 0.75rem;
              color: ${({ theme }) => theme.colors?.info};
            }
          }

          .options {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 14px;

            .option {
              width: 33.333%;
              display: flex;
              align-items: center;
              cursor: pointer;

              .radio-button {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background-color: ${({ theme }) => theme.colors?.primaryFocus};
                margin-right: 10px;

                .dot {
                  width: 18px;
                  height: 18px;
                  border-radius: 50%;
                  background-color: ${({ theme }) => theme.colors?.primary};
                }
              }

              p {
                font-size: 0.875rem;
                font-weight: 400;
                color: ${({ theme }) => theme.colors?.secondary};
              }
            }
          }
        }

        .cta-view {
          width: 100%;
          display: flex;
          margin-top: 100px;
          margin-bottom: 50px;
          justify-content: space-between;

          button {
            width: 100%;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 16px;
          }

          .cta-view__save {
            background-color: ${({ theme }) => theme.colors?.primary};
            color: ${({ theme }) => theme.colors?.white};
            margin-bottom: 20px;
          }
        }
      }
    }
  }
`;
