import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/icons/delete.icon.svg';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/nav-down.icon.svg';
import { ReactComponent as DragIcon } from '@/assets/icons/drag-and-drop.svg';
import { numberWithCommas } from '@/utils/helpers';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';
import { device } from '@/constants/breakpoints';
import { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';

const discountOptions = [
  { value: 'percentage', label: '%' },
  { value: 'fixed', label: 'NGN' }
];

export default function DynamicForm({
  rows,
  setRows,
  tax,
  setTax,
  discount,
  setDiscount,
  taxType,
  discountType,
  setDiscountType,
  showTaxOptions,
  setShowTaxOptions,
  showDiscountOptions,
  setShowDiscountOptions,
  handleSubmit,
  handleCreateInvoice,
  saveStatus,
  setSaveStatus,
  vatBeforeDiscount,
  setVatBeforeDiscount
}) {
  const handleInputChange = (index, e) => {
    const { name, value, type } = e.target;
    const updatedValue = type === 'number' ? value.replace(/[^0-9.]/g, '') : value;
    const updatedRows = [...rows];
    updatedRows[index][name] = updatedValue;

    // Calculate the amount for the row
    if (name === 'quantity' || name === 'rate') {
      const quantity = parseFloat(updatedRows[index].quantity);
      const rate = parseFloat(updatedRows[index].rate);
      const amount = isNaN(quantity) || isNaN(rate) ? '' : (quantity * rate).toFixed(2);
      updatedRows[index].amount = amount;
    }

    setRows(updatedRows);
  };

  const handleVatInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'tax') {
      setTax(value);
    } else {
      setDiscount(value);
    }
  };

  const handleAddRow = () => {
    if (rows.length === 0 || rows.every((r) => r.item.trim() !== '' && parseFloat(r.rate) > 0)) {
      setRows([...rows, { item: '', quantity: 1, rate: '', amount: '' }]);
    } else {
      const invalidIndex = rows.findIndex(
        (r) => r.item.trim() === '' || isNaN(parseFloat(r.rate)) || parseFloat(r.rate) <= 0
      );

      toast.error(`Please fill in the required fields for item ${invalidIndex + 1}`);
    }
  };

  const handleRemoveRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);
    setRows(updatedRows);
  };

  const calculateSubtotal = useMemo(() => {
    return rows.reduce((subtotal, row) => {
      const amount = parseFloat(row.amount);
      return isNaN(amount) ? subtotal : subtotal + amount;
    }, 0);
  }, [rows]);

  const calculateDiscount = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const discountValue = parseFloat(discount) || 0;

    if (discountType.value === 'percentage') {
      return ((subtotal * discountValue) / 100).toFixed(2);
    } else {
      return discountValue.toFixed(2);
    }
  }, [calculateSubtotal, discount, discountType]);

  const calculateVAT = useMemo(() => {
    if (!tax) return 0;

    const subtotal = parseFloat(calculateSubtotal) || 0;
    const taxValue = parseFloat(tax);
    const taxPercentage = taxType?.value === 'percentage' ? taxValue / 100 : taxValue;
    const discountAmount = parseFloat(calculateDiscount) || 0;

    if (vatBeforeDiscount) {
      return (subtotal * taxPercentage).toFixed(2);
    } else {
      const amountAfterDiscount = subtotal - discountAmount;
      return (amountAfterDiscount * taxPercentage).toFixed(2);
    }
  }, [tax, taxType, calculateSubtotal, calculateDiscount, vatBeforeDiscount]);

  const calculateTotal = useMemo(() => {
    const subtotal = parseFloat(calculateSubtotal) || 0;
    const vatAmount = parseFloat(calculateVAT) || 0;
    const discountAmount = parseFloat(calculateDiscount) || 0;

    if (vatBeforeDiscount) {
      // Add VAT to subtotal first, then subtract discount
      return (subtotal + vatAmount - discountAmount).toFixed(2);
    } else {
      // Subtract discount from subtotal first, then add VAT
      return (subtotal - discountAmount + vatAmount).toFixed(2);
    }
  }, [calculateSubtotal, calculateVAT, calculateDiscount, vatBeforeDiscount]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const updatedRows = Array.from(rows);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setRows(updatedRows);
  };

  const handleButtonClick = (status) => {
    if (rows.length === 0 || rows.every((r) => r.item.trim() !== '' && parseFloat(r.rate) > 0)) {
      setSaveStatus(status);
      handleSubmit(status, rows, tax, discount, taxType, discountType);
    } else {
      const invalidIndex = rows.findIndex(
        (r) => r.item.trim() === '' || isNaN(parseFloat(r.rate)) || parseFloat(r.rate) <= 0
      );

      toast.error(`Please fill in the required fields for item ${invalidIndex + 1}`);
    }


  };

  return (
    <Form>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable-rows">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {rows.map((row, index) => (
                <Draggable key={`row-${index}`} draggableId={`row-${index}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={`input-row ${snapshot.isDragging ? 'dragging' : ''}`}>
                      <div className="drag-handle" {...provided.dragHandleProps}>
                        <DragIcon />
                      </div>
                      <div className="input-left">
                        <div className="input-view">
                          <label htmlFor="item">Item*</label>
                          <input
                            type="text"
                            placeholder="Item"
                            name="item"
                            value={row.item}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                      </div>
                      <div className="input-right">
                        <div className="input-view quantity-view">
                          <label htmlFor="quantity">Quantity</label>
                          <input
                            type="number"
                            placeholder="Quantity"
                            name="quantity"
                            onKeyDown={(e) => {
                              const allowedKeys = [8, 46];
                              if (
                                !allowedKeys.includes(e.keyCode) &&
                                (e.keyCode < 48 || e.keyCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            min="0"
                            value={row.quantity}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                        <div className="input-view">
                          <label htmlFor="rate">Rate(₦)*</label>
                          <input
                            type="number"
                            placeholder="Rate"
                            min={0}
                            name="rate"
                            value={row.rate}
                            onKeyDown={(e) => {
                              const allowedKeys = [8, 46, 190];
                              if (
                                !allowedKeys.includes(e.keyCode) &&
                                (e.keyCode < 48 || e.keyCode > 57)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => handleInputChange(index, e)}
                          />
                        </div>
                        <div className="input-view">
                          <label htmlFor="amount">Amount(₦)</label>
                          <div className="amount">{numberWithCommas(row.amount || '00.00')}</div>
                        </div>
                      </div>
                      {index > 0 && (
                        <button
                          type="button"
                          className="remove"
                          onClick={() => handleRemoveRow(index)}>
                          <DeleteIcon />
                        </button>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button type="button" className="add" onClick={handleAddRow}>
        <PlusIcon /> Add item
      </button>
      <div className="summary-view">
        <div className="item">
          <h4 className="name">Subtotal</h4>
          <h4 className="value">₦{numberWithCommas(calculateSubtotal || '00.00')}</h4>
        </div>
        <div className="item discount-item">
          <div className="discount-view">
            <h4 className="name">Discount</h4>
            <div className="discount">
              <input
                type="number"
                name="discount"
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
              />
              <div
                className="select-view"
                onClick={() => {
                  setShowDiscountOptions(!showDiscountOptions);
                  setShowTaxOptions(false);
                }}>
                <span>{discountType?.label}</span> <ArrowDownIcon />
                {showDiscountOptions && (
                  <div className="options">
                    {discountOptions.map((option) => (
                      <button
                        key={option.value}
                        className="option"
                        type="button"
                        onClick={() => {
                          setDiscountType(option);
                          setShowDiscountOptions(false);
                        }}>
                        {option?.label}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          <h4 className="value">₦{numberWithCommas(calculateDiscount || '00.00')}</h4>
        </div>
        <div className="item discount-item">
          <div className="discount-view">
            <h4 className="name">VAT</h4>
            <div className="discount">
              <input
                type="number"
                name="tax"
                value={tax}
                onChange={(e) => setTax(e.target.value)}
              />
              <div
                className="select-view"
                onClick={() => {
                  setShowDiscountOptions(false);
                  setShowTaxOptions(!showTaxOptions);
                }}>
                <span>{taxType?.label}</span>
              </div>
            </div>
          </div>
          <h4 className="value">₦{numberWithCommas(calculateVAT || '00.00')}</h4>
        </div>
        {tax > 0 && (
          <div className="vat-preference">
            <h4 className="name">How do you want VAT be charged?</h4>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  name="vatPreference"
                  value="beforeDiscount"
                  checked={vatBeforeDiscount}
                  onChange={() => setVatBeforeDiscount(true)}
                />
                Before discount
              </label>
              <label>
                <input
                  type="radio"
                  name="vatPreference"
                  value="afterDiscount"
                  checked={!vatBeforeDiscount}
                  onChange={() => setVatBeforeDiscount(false)}
                />
                After discount
              </label>
            </div>
          </div>
        )}
        <div className="item total">
          <h4 className="name">Total</h4>
          <h4 className="value">₦{numberWithCommas(calculateTotal || '00.00')}</h4>
        </div>
      </div>
      <div className="cta-view">
        <button
          type="button"
          className="cta-view__save"
          onClick={() => handleButtonClick('sent')}>
          {handleCreateInvoice.isLoading && saveStatus === 'sent' ? (
            <Oval
              color="#FFF"
              secondaryColor="#ddd"
              height={30}
              width={30}
              strokeWidth={4}
              ariaLabel="loading"
            />
          ) : (
            'Continue'
          )}
        </button>
        <button
          type="button"
          className="cta-view__draft"
          onClick={() => handleButtonClick('draft')}>
          {handleCreateInvoice.isLoading && saveStatus === 'draft' ? (
            <Oval
              color="#FFF"
              secondaryColor="#ddd"
              height={30}
              width={30}
              strokeWidth={4}
              ariaLabel="loading"
            />
          ) : (
            'Save as draft'
          )}
        </button>
      </div>
    </Form>
  );
}

const Form = styled.form`
  width: 100%;
  max-width: 700px;

  @media ${device.phone} {
    max-width: 100%;
  }

  .input-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    transition: box-shadow 0.3s ease;

    &.dragging {
      box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.09);
    }

    @media ${device.phone} {
      width: calc(100% - 20px);
      flex-direction: column;
      align-items: flex-start;
      border-bottom: 1px solid ${({ theme }) => theme.colors?.line};
      margin-bottom: 20px;
    }

    .drag-handle {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
      cursor: grab;
    }

    .input-right {
      width: 58%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media ${device.phone} {
        width: 100%;
      }

      .input-view {
        width: 38%;
      }

      .quantity-view {
        width: 20%;
      }
    }

    .input-left {
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media ${device.phone} {
        width: 100%;
      }
    }

    .input-view {
      width: 100%;
      margin-bottom: 30px;

      @media ${device.phone} {
        width: 100%;
        margin-bottom: 20px;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .amount {
        color: ${({ theme }) => theme.colors?.activeTitle};
        background-color: ${({ theme }) => theme.colors?.inputBackground};
        border: 1px solid ${({ theme }) => theme.colors?.inputBackground};
        height: 50px;
        border-radius: 8px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 5px;
        overflow-x: scroll;
      }

      input {
        width: 100%;
        height: 50px;
        border-radius: 8px;
        border: 1px solid ${({ theme }) => theme.colors?.border};
        padding: 0 16px;
        outline: none;
        font-size: 1rem;
        font-weight: 300;
        transition: all 0.2s ease-in-out;
        margin-top: 5px;
        color: ${({ theme }) => theme.colors?.activeTitle};
        background-color: ${({ theme }) => theme.colors?.inputBackground};
        border: 1px solid ${({ theme }) => theme.colors?.inputBackground};

        &::placeholder {
          color: ${({ theme }) => theme.colors?.placeholder};
          font-size: 0.875rem;
          font-weight: 300;
        }
      }
    }

    .remove {
      position: absolute;
      top: 50%;
      right: -20px;
      transform: translateY(-50%);
      background-color: transparent;
    }
  }

  .summary-view {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    margin-top: 50px;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 20px;
      width: 400px;

      @media ${device.phone} {
        width: 100%;
      }

      .name {
        width: 100px;
        color: ${({ theme }) => theme.colors?.activeTitle};
        font-weight: 500;
        font-size: 0.875rem;
      }

      .value {
        width: 200px;
        text-align: right;
        color: ${({ theme }) => theme.colors?.activeTitle};
        font-weight: 500;
        font-size: 0.875rem;
      }
    }

    .discount-item {
      .discount-view {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .discount {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          input {
            width: 60%;
            border: none;
            outline: none;
            background-color: #f0f0f0;
            border-radius: 6px 0 0 6px;
            height: 35px;
            padding: 0 6px;
            font-size: 0.75rem;
          }

          .select-view {
            width: 40%;
            height: 35px;
            background-color: #dfdfdf;
            border-radius: 0 6px 0px 0;
            position: relative;
            padding: 0 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            span {
              font-weight: 600;
              font-size: 0.75rem;
            }

            svg {
            }

            .options {
              position: absolute;
              top: 35px;
              right: 0;
              width: 100%;
              background-color: #dfdfdf;
              display: flex;
              flex-direction: column;
              border-radius: 0 0 6px 6px;
              overflow: hidden;
              z-index: 100;

              .option {
                padding: 5px 0;
                font-size: 0.75rem;

                &:hover {
                  background-color: #f0f0f0;
                }
              }
            }
          }
        }
      }
    }

    .vat-preference {
      margin-top: 10px;
      justify-content: flex-start;
      width: 55%;
      margin-bottom: 50px;

      .name {
        font-size: 1rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors?.activeTitle};
        margin-bottom: 10px;
      }

      .radio-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
      }

      .radio-group label {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        color: ${({ theme }) => theme.colors?.secondary};
      }

      .radio-group input[type='radio'] {
        width: 24px;
        height: 24px;
        margin: 0;
        accent-color: ${({ theme }) => theme.colors?.secondary};
      }
    }

    .total {
      background-color: ${({ theme }) => theme.colors?.inputBackground};
      padding: 10px 20px;

      .name {
        font-weight: 600;
      }

      .value {
        font-weight: 600;
      }
    }
  }

  .cta-view {
    width: 100%;
    display: flex;
    margin-top: 60px;
    margin-bottom: 50px;
    justify-content: center;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
    }

    button {
      width: 40%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      font-weight: 500;
      border-radius: 16px;

      @media ${device.phone} {
        width: 100%;
      }
    }

    .cta-view__save {
      background-color: ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
      margin-bottom: 20px;
      margin-right: 20px;
    }

    .cta-view__draft {
      background-color: ${({ theme }) => theme.colors?.white};
      color: ${({ theme }) => theme.colors?.primary};
      border: 1px solid ${({ theme }) => theme.colors?.primary};
    }
  }

  .add {
    border-radius: 8px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
    }
  }
`;
