/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import CurrencyyInput from '@/components/CurrencyInput';
import TextArea from '@/components/TextArea/TextArea';
import FilePicker from '@/components/FilePicker';
import { fetchCustomers } from '@/services/customerApi';
import { generateBillNumber } from '@/services/billsApi';
import { createInvoice } from '@/services/receivablesApi';
import FetchLoader from '@/components/FetchLoader';
import Input from '@/components/Input/Input';
import { fetchUser } from '@/services/userApi';
import { appSelector } from '@/redux/features/app.slice';
import { device } from '@/constants/breakpoints';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' }
];

const schema = yup
  .object({
    amount: yup.string().required('Amount is required'),
    billOrInvoiceNumber: yup.string().required('Invoice number is required'),
    dueDate: yup.string().required('Due date is required'),
    description: yup.string().notRequired().nullable()
  })
  .required();

function BillCustomerForm({ showModal, setShowModal }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { customerToBill } = useSelector(appSelector);
  const [frequency, setFrequency] = useState(billFrequency[0]);
  const [billOrInvoiceNumber, setBillOrInvoiceNumber] = useState('');
  const [file, setFile] = useState(null);

  const customers = useQuery({
    queryKey: ['customers', { page: 1, pageLimit: 1000 }],
    queryFn: fetchCustomers,
    onSuccess: () => {},
    onError: () => {}
  });

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onSuccess: () => {},
    onError: () => {}
  });

  const getBillNumber = useQuery({
    queryKey: [''],
    queryFn: generateBillNumber,
    onSuccess: (data) => {
      setBillOrInvoiceNumber(data?.data?.billNumber);
    },
    onError: () => {}
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      billOrInvoiceNumber
    }
  });

  const handleSaveInvoice = useMutation(createInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      navigate('/get-paid/invoices?tab=draft');
      toast.success('Invoice created successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleSendInvoice = useMutation(createInvoice, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['invoicesMetrics'] });
      if (user?.data?.data?.hasBankAccount) {
        navigate(`/get-paid/invoices/request-payment?id=${data?.data?.id}`);
        toast.success('Invoice created successfully');
      } else {
        navigate('/settings?tab=bank-account');
        toast.success('Invoice created successfully, add bank account to start receiving payments');
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  useEffect(() => {
    reset({
      billOrInvoiceNumber
    });
  }, [billOrInvoiceNumber, reset]);

  const onSaveInvoice = (data) => {
    const formData = new FormData();
    formData.append('customerId', customerToBill?.id);
    formData.append('billOrInvoiceNumber', data?.billOrInvoiceNumber);
    formData.append('dueDate', new Date(data?.dueDate).toISOString());
    formData.append('amount', data?.amount);
    formData.append('frequency', frequency?.value);
    formData.append('description', data?.description ? data?.description : '');

    if (file) {
      formData.append('document', file);
    }
    formData.append('status', 'draft');

    handleSaveInvoice.mutate(formData);
  };

  const onSendInvoice = (data) => {
    const formData = new FormData();
    formData.append('customerId', customerToBill?.id);
    formData.append('billOrInvoiceNumber', data?.billOrInvoiceNumber);
    formData.append('dueDate', new Date(data?.dueDate).toISOString());
    formData.append('amount', data?.amount);
    formData.append('frequency', frequency?.value);
    formData.append('description', data?.description ? data?.description : '');
    if (file) {
      formData.append('document', file);
    }
    formData.append('status', 'sent');
    handleSendInvoice.mutate(formData);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 500 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.2 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======= Content here ====== */}
            <div className="header-view">
              <button type="button" className="back" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </div>

            {customers?.isFetching || getBillNumber?.isFetching || user?.isFetching ? (
              <FetchLoader />
            ) : (
              <FormView>
                <form>
                  {/* Vendor */}
                  <div className="input-view vendor">
                    <label htmlFor="vendor">Customer</label>
                    <Input
                      value={
                        customerToBill?.type === 'business'
                          ? customerToBill?.companyName
                          : customerToBill?.name
                      }
                    />
                  </div>
                  <div className="add-view">
                    {/* <button type="button" onClick={() => setShowCustomerForm(true)}>
                      <PlusIcon /> Add new customer
                    </button> */}
                  </div>

                  {/* Bill amount */}
                  <div className="input-view">
                    <label htmlFor="name">Amount</label>
                    <Controller
                      name="amount"
                      control={control}
                      defaultValue=""
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value, name } }) => (
                        <CurrencyyInput
                          name={name}
                          placeholder="Enter amount"
                          decimalsLimit={2}
                          allowNegativeValue={false}
                          prefix="₦"
                          onValueChange={onChange}
                          value={value}
                          onBlur={onBlur}
                          error={errors?.amount?.message}
                          className={`currency-input ${
                            errors?.amount && dirtyFields?.amount
                              ? ' has-error'
                              : dirtyFields?.amount
                                ? 'valid'
                                : ''
                          }`}
                        />
                      )}
                    />

                    {errors?.amount && (
                      <span className="error-label">{errors?.amount?.message}</span>
                    )}
                  </div>

                  {/* Bill frequency */}
                  <div className="select-view">
                    <label htmlFor="invoiceFrequency">Invoice frequency</label>
                    <div className="options">
                      {billFrequency.map((item) => (
                        <div
                          className="option"
                          key={item?.value}
                          onClick={() => setFrequency(item)}>
                          <div className="radio-button">
                            {frequency?.id === item?.id ? <span className="dot"></span> : null}
                          </div>
                          <p>{item?.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Bill number */}
                  <div className="input-view">
                    <label htmlFor="billOrInvoiceNumber">Invoice no.</label>
                    <Input
                      type="text"
                      {...register('billOrInvoiceNumber')}
                      error={errors?.billOrInvoiceNumber?.message}
                      placeholder="Enter invoice number"
                    />

                    {errors?.billOrInvoiceNumber && (
                      <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                    )}
                  </div>

                  {/* Due date */}
                  <div className="input-view">
                    <label htmlFor="name">Due date</label>
                    <Input type="date" {...register('dueDate')} error={errors?.dueDate?.message} />

                    {errors?.dueDate && (
                      <span className="error-label">{errors?.dueDate?.message}</span>
                    )}
                  </div>

                  {/* Description */}
                  <div className="input-view">
                    <label htmlFor="description">Description</label>
                    <TextArea
                      type="number"
                      {...register('description')}
                      placeholder="Enter description"
                    />

                    {errors?.description && (
                      <span className="error-label">{errors?.description?.message}</span>
                    )}
                  </div>

                  {/* Document */}
                  <FilePicker file={file} setFile={setFile} acceptsPDF />

                  {/* ===== CTA ====== */}
                  <CTAView>
                    <button
                      type="button"
                      className="cta-view__save"
                      onClick={handleSubmit(onSendInvoice)}>
                      {handleSendInvoice.isLoading ? (
                        <Oval
                          color="#FFF"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Continue'
                      )}
                    </button>
                    <button
                      type="button"
                      className="cta-view__draft"
                      onClick={handleSubmit(onSaveInvoice)}>
                      {handleSaveInvoice.isLoading ? (
                        <Oval
                          color="#92C22C"
                          secondaryColor="#ddd"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Save as draft'
                      )}
                    </button>
                  </CTAView>
                </form>
              </FormView>
            )}
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

export default BillCustomerForm;

BillCustomerForm.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool
};

const ModalView = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 101;
    height: 100%;
    width: 1300px;
    margin: 0 auto;
    padding-top: 50px;

    @media ${device.phone} {
      width: 100%;
      padding: 30px 16px;
    }
  }
`;

const FormView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;

  form {
    width: 100%;
    max-width: 460px;

    .input-view {
      width: 100%;
      margin-bottom: 30px;

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .vendor {
      margin-bottom: 10px;
    }

    .add-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      button {
        color: ${({ theme }) => theme.colors?.secondary};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 10px;
        }
      }
    }

    .select-view {
      width: 100%;
      margin-bottom: 20px;

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .option {
          width: 33.333%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .radio-button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors?.primaryFocus};
            margin-right: 10px;

            .dot {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: ${({ theme }) => theme.colors?.primary};
            }
          }

          p {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors?.secondary};
          }
        }
      }
    }
  }
`;

const CTAView = styled.div`
  width: 100%;
  display: flex;
  margin-top: 40px;
  margin-bottom: 50px;
  justify-content: space-between;

  button {
    width: 49%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 16px;
  }

  .cta-view__save {
    background-color: ${({ theme }) => theme.colors?.primary};
    color: ${({ theme }) => theme.colors?.white};
    margin-bottom: 20px;
  }

  .cta-view__draft {
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.primary};
    border: 1px solid ${({ theme }) => theme.colors?.primary};
  }
`;
