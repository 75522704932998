import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as InflowIcon } from '@/assets/icons/inflow.icon.svg';
import { ReactComponent as OutflowIcon } from '@/assets/icons/outflow.icon.svg';
import { ReactComponent as EyeIcon } from '@/assets/icons/eye2.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off2.icon.svg';
import { ReactComponent as BankIcon } from '@/assets/icons/bank2.icon.svg';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy2.icon.svg';
import { numberWithCommas } from '@/utils/helpers';
import useWallet from '@/hooks/useWallet';
import { fetchUser } from '@/services/userApi';
import { useQuery } from '@tanstack/react-query';
import { device } from '@/constants/breakpoints';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector, setShowAccountBalance } from '@/redux/features/app.slice';

const BankAccountMetrics = () => {
  const dispatch = useDispatch();
  const { showAccountBalance } = useSelector(appSelector);
  const { bankingAccount, userWallet } = useWallet();

  const copyAccountDetails = (bankName, accountNumber, accountName) => {
    const text = `${bankName?.toUpperCase()} \n${accountNumber}\n${accountName}`;
    navigator.clipboard.writeText(text);
    toast.success('Account details copied to clipboard');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Account number copied to clipboard');
  };

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  return (
    <BankAccountMetricsStyles>
      <ActiveView>
        {user?.data?.data?.complianceVerified ? (
          <div className="label-verified">
            <h3 className="label-text">Active</h3>
          </div>
        ) : (
          <div className="label">
            <h3 className="label-text">Not Active</h3>
          </div>
        )}
        {!user?.data?.data?.complianceVerified && (
          <div className="activate-link">
            <Link to="/settings?tab=compliance">Activate now</Link>
          </div>
        )}
      </ActiveView>
      <AccountMetrics>
        <div className="account-info-view">
          <div className="icon">
            <BankIcon />
          </div>
          <div className="info-view-text">
            <h3 className="info-view-text-title">Bank Account</h3>
            <p className="info-view-text-subtitle">
              {bankingAccount?.bankName}: {bankingAccount?.accountNumber || 'XXXXXXXXXX'}{' '}
              <button
                className="icon-copy"
                onClick={() => {
                  copyToClipboard(
                    bankingAccount?.accountNumber ? bankingAccount?.accountNumber : 'XXXXXXXXXX'
                  );
                }}>
                <CopyIcon />
              </button>
            </p>
            <button
              className="copy-details"
              onClick={() => {
                copyAccountDetails(
                  bankingAccount?.bankName,
                  bankingAccount?.accountNumber ? bankingAccount?.accountNumber : 'XXXXXXXXXX',
                  bankingAccount?.accountName
                );
              }}>
              Copy account details
            </button>
          </div>
        </div>

        <div className="account-balace-view">
          <div className="balance">
            <h3 className="balance-view-text-title">Balance</h3>
            <button
              className="btn"
              onClick={() => dispatch(setShowAccountBalance(!showAccountBalance))}>
              {showAccountBalance ? <EyeOffIcon className="eye" /> : <EyeIcon className="eye" />}
            </button>
          </div>
          <p className={`balance-view-text-subtitle ${!showAccountBalance ? 'hidden-blur' : ''}`}>
            ₦ {numberWithCommas(userWallet?.currentBalance || '00.00')}
          </p>
        </div>

        <div className="inflow-view">
          <div className="icon">
            <InflowIcon />
          </div>
          <p className={`info-view-text-subtitle ${!showAccountBalance ? 'hidden-blur' : ''}`}>
            ₦ {numberWithCommas(userWallet?.totalInflow || '00.00')}
          </p>
        </div>

        <div className="outflow-view">
          <div className="icon">
            <OutflowIcon />
          </div>
          <p className={`info-view-text-subtitle ${!showAccountBalance ? 'hidden-blur' : ''}`}>
            ₦ {numberWithCommas(userWallet?.totalOutflow || '00.00')}
          </p>
        </div>
      </AccountMetrics>
    </BankAccountMetricsStyles>
  );
};

export default BankAccountMetrics;

const BankAccountMetricsStyles = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 0.6rem;
  padding: 1.5rem 1rem;
  margin-bottom: 10px;
`;

const ActiveView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;

  .label {
    background-color: ${({ theme }) => theme.colors.errorBackground};
    border-radius: 1.6rem;
    padding: 0.2rem 0.5rem;

    .label-text {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.error_200};
      font-weight: 500;
    }
  }

  .label-verified {
    background-color: ${({ theme }) => theme.colors.success_200};
    border-radius: 1.6rem;
    padding: 0.2rem 0.5rem;

    .label-text {
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.black};
      font-weight: 500;
    }
  }

  .activate-link {
    margin-left: 10px;
    font-size: 0.875rem;
    font-weight: 500;

    a {
      color: ${({ theme }) => theme.colors.primary};
      text-decoration: underline;
      font-weight: 500;
    }
  }
`;

const AccountMetrics = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 10px;
  }

  .account-info-view {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 1rem;
    }

    .info-view-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .info-view-text-title {
        font-size: 1.2rem;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.white};
        margin-bottom: 0.5rem;
      }

      .info-view-text-subtitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.white};

        button {
          margin-left: 5px;
          svg {
            stroke: ${({ theme }) => theme.colors.primary};
            width: 14px;
            height: 14px;
          }
        }
      }

      .copy-details {
        font-size: 0.875rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.white};
        margin-top: 10px;
        cursor: pointer;
        border: 1px solid ${({ theme }) => theme.colors.primary};
        border-radius: 34px;
        padding: 12px 20px;
      }
    }
  }

  .account-balace-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.phone} {
      width: 100%;
      margin-top: 10px;
    }

    .balance-view-text-title {
      font-size: 0.875rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.white};
    }

    .hidden-balance {
      margin-top: 0.5rem;
      margin-bottom: -0.5rem;
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.white};
    }

    .hidden-blur {
      filter: blur(8px);
    }

    .balance-view-text-subtitle {
      font-size: 1.2rem;
      font-weight: 600;
      transition: filter 0.3s ease-in-out;
      color: ${({ theme }) => theme.colors.white};
    }

    .balance {
      display: flex;
      justify-content: center;
      gap: 1rem;
      align-items: center;

      .balance-view-text-title {
        font-size: 0.875rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
        color: ${({ theme }) => theme.colors.white};
      }

      .btn {
        border: none;
        background: transparent;
        margin-top: -0.5rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .eye {
          fill: none;
        }
      }
    }
  }

  .inflow-view,
  .outflow-view {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media ${device.phone} {
      width: 100%;
      margin-top: 20px;
    }

    .icon {
      margin-bottom: 0.5rem;
    }

    .hidden-blur {
      filter: blur(8px);
    }

    .hidden-balance {
      /* margin-top: 0.5rem; */
      /* margin-bottom: -0.5rem; */
      font-size: 2.5rem;
      font-weight: 600;
      line-height: 20px;
      color: ${({ theme }) => theme.colors.white};
    }

    .info-view-text-subtitle {
      font-size: 1.2rem;
      font-weight: 600;
      transition: filter 0.3s ease-in-out;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
