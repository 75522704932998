/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
import React from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { createBulkUpload } from '@/services/billsApi';
import { device } from '@/constants/breakpoints';

function ConfirmCSVModal({ showModal, setShowModal, parsedCSV }) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleUploadBulkInvoice = useMutation(createBulkUpload, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      navigate('/make-payments/bills?tab=draft');
      toast.success('Invoices uploaded successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = () => {
    function convertToIsoDate(dateStr) {
      const [day, month, year] = dateStr.split('/');
      const isoDate = new Date(`${year}-${month}-${day}`).toISOString();
      return isoDate;
    }

    const bulk = parsedCSV.map((invoice) => ({
      ...invoice,
      dueDate: convertToIsoDate(invoice.dueDate),
      amount: `${invoice?.amount?.replace(',', '')}`,
      email: invoice.email || null,
      billOrInvoiceNumber: invoice.invoiceNumber
    }));
    const data = {
      items: bulk
    };

    handleUploadBulkInvoice.mutate(data);
  };

  return (
    <AnimatePresence>
      {showModal && (
        <Overlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}>
          <ConfirmCSVModalView
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 1000 }}
            transition={{ duration: 0.3 }}>
            <HeaderView>
              <button type="button" onClick={() => setShowModal(false)}>
                <BackIcon />
              </button>
            </HeaderView>

            <ContentView>
              <FlexView>
                <div className="col1">
                  <h3>Confirm your invoices</h3>
                  <p>You can always go back to make edits.</p>
                </div>

                <div className="col2">
                  <div className="table">
                    <div className="table-head">
                      <span className="company">Company Name</span>
                      <span>Description</span>
                      <span>Amount</span>
                      <span>Invoice #</span>
                      <span>Due date</span>
                      <span>Email (Optional)</span>
                    </div>
                    <div className="table-body">
                      {parsedCSV?.slice(0, 5)?.map((invoice, index) => (
                        <div className="table-row" key={index}>
                          <span className="company">{invoice?.companyName}</span>
                          <span>{invoice?.description}</span>
                          <span>{invoice?.amount}</span>
                          <span>{invoice?.invoiceNumber}</span>
                          <span>{invoice?.dueDate}</span>
                          <span>{invoice?.email}</span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="continue">
                    <button onClick={onSubmit} type="button">
                      {handleUploadBulkInvoice.isLoading ? (
                        <Oval
                          color="#92C22C"
                          secondaryColor="#fff"
                          height={30}
                          width={30}
                          strokeWidth={4}
                          ariaLabel="loading"
                        />
                      ) : (
                        'Upload invoice'
                      )}
                    </button>
                  </div>
                </div>
              </FlexView>
            </ContentView>
          </ConfirmCSVModalView>
        </Overlay>
      )}
    </AnimatePresence>
  );
}

export default ConfirmCSVModal;

const Overlay = styled(motion.div)`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors?.white};
  z-index: 20000;
  overflow-y: scroll;
`;

const ConfirmCSVModalView = styled(motion.div)`
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    padding: 0 16px;
    padding-top: 30px;
  }
`;

const HeaderView = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const ContentView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FailedView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;

  h3 {
    font-size: 2rem;
    max-width: 100%;
    font-weight: 600;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors?.secondary};
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 19px;
    max-width: 40%;
    text-align: center;
  }

  button {
    background-color: ${({ theme }) => theme.colors?.primary};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors?.white};
    height: 63px;
    width: 230px;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 50px;
  }
`;

const FlexView = styled.div`
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .col1 {
    width: 50%;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    h3 {
      font-size: 2rem;
      max-width: 100%;
      font-weight: 600;
      margin-bottom: 10px;
      color: ${({ theme }) => theme.colors?.secondary};
    }

    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .col2 {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${device.phone} {
      width: 100%;
    }

    .table {
      width: 100%;
      margin-top: 0px;

      .table-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: ${({ theme }) => theme.colors?.primary};
        padding: 10px;

        span {
          font-size: 0.75rem;
          font-weight: 600;
          line-height: 19px;
          color: ${({ theme }) => theme.colors?.secondary};
          flex: 1;

          @media ${device.phone} {
            font-size: 0.6rem;
          }
        }

        .company {
          flex: 1.5;
        }
      }

      .table-body {
        width: 100%;
        .table-row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #d3e6ff;
          padding: 10px;

          &:nth-child(even) {
            background-color: ${(props) => props.theme.colors?.white};
          }

          span {
            font-size: 0.75rem;
            font-weight: 400;
            line-height: 19px;
            color: ${({ theme }) => theme.colors?.black};
            flex: 1;

            @media ${device.phone} {
              font-size: 0.6rem;
            }
          }

          .company {
            flex: 1.5;
          }
        }
      }
    }

    .continue {
      margin-top: 60px;

      button {
        background-color: ${({ theme }) => theme.colors?.primary};
        border-radius: 16px;
        color: ${({ theme }) => theme.colors?.white};
        height: 63px;
        width: 230px;
        font-size: 1rem;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
