import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import secureLocalStorage from 'react-secure-storage';

import { Link, Navigate, useNavigate } from 'react-router-dom';
import Button from '@/components/Button/Button';
import {
  ButtonView,
  FormView,
  InputView,
  LoginLeftView,
  LoginRightView,
  LoginView,
  LogoView,
  PasswordInputView,
  RegisterView,
  TitleView
} from './login.styles';
import Input from '@/components/Input/Input';
import { ReactComponent as Logo } from '@/assets/icons/logo-beta.svg';
import { ReactComponent as EyeeIcon } from '@/assets/icons/eye.icon.svg';
import { ReactComponent as EyeOffIcon } from '@/assets/icons/eye-off.icon.svg';
import { ReactComponent as AuthFrame } from '@/assets/images/authFrame2.image.svg';
import { useDispatch } from 'react-redux';
import { loginUser } from '@/services/authApi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';
import theme from '@/constants/theme';
import { fetchUser } from '@/services/userApi';
import useAuthentication from '@/hooks/useAuthentication';
import Auth2FAModal from './Auth2FAModal';

const schema = yup
  .object({
    email: yup.string().email('Invalid email format').required('Email is required'),
    password: yup
      .string()
      .min(8, 'Password should not be less than 8 characters long')
      .required('Password is required')
  })
  .required();

const Login = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [show2faModal, setShow2faModal] = useState(false);
  const [loginData, setLoginData] = useState({});
  const { authenticated } = useAuthentication();

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const handleLoginUser = useMutation(loginUser, {
    onSuccess: async (data) => {
      if (data?.data?.user?.hasLogin2faEnabled) {
        setShow2faModal(true);
      } else {
        queryClient.invalidateQueries({ queryKey: ['user'] });
        secureLocalStorage.setItem('ut', data?.data?.accessToken);
        secureLocalStorage.setItem('rt', data?.data?.refreshToken);
        secureLocalStorage.setItem('auth', true);

        const user = await queryClient.fetchQuery({
          queryKey: ['user'],
          queryFn: fetchUser
        });

        if (user) {
          const company = user?.data?.company?.id;
          if (company && user?.data?.hasCompletedOnboarding) {
            secureLocalStorage.setItem('company', company);
            secureLocalStorage.setItem('auth', true);
            navigate('/dashboard', { replace: true });
          } else {
            secureLocalStorage.setItem('auth', true);
            navigate('/onboarding?page=company-name', { replace: true });
          }
        }
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleLogin = useCallback(
    async (data) => {
      setLoginData(data);
      const isValid = await trigger();
      if (isValid) {
        handleLoginUser.mutate(data);
      }
    },
    [dispatch, trigger]
  );

  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <LoginView data-testid="login-view">
      <LoginRightView>
        <LogoView>
          <Logo className="logo-icon" data-testid="logo-icon" />
        </LogoView>

        <div className="title">
          <h1>Pay & Get Paid on time, every time</h1>
        </div>

        <div className="auth-frame">
          <AuthFrame />
        </div>
      </LoginRightView>

      <LoginLeftView>
        <TitleView>
          <h3>Welcome back!</h3>
          <p>Input your login details</p>
        </TitleView>
        <FormView onSubmit={handleSubmit(handleLogin)}>
          <InputView>
            <label htmlFor="email">Email</label>
            <Input
              type="email"
              id="email"
              placeholder="Enter email"
              {...register('email')}
              error={!!errors?.email?.message}
            />

            {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
          </InputView>

          <PasswordInputView>
            <label htmlFor="password">Password</label>
            <div className="input">
              <Input
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Enter password"
                {...register('password')}
                error={!!errors?.password?.message}
              />
              <button type="button" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <EyeOffIcon /> : <EyeeIcon />}
              </button>
            </div>
            {errors?.password && <span className="error-label">{errors?.password?.message}</span>}
          </PasswordInputView>

          <ButtonView>
            <div className="reset-view">
              <span>Forgot password? </span>
              <Link to="/forgot-password" aria-label="Reset password">
                Reset
              </Link>
            </div>
            <Button width="100%" height="64px" type="submit">
              {handleLoginUser.isLoading ? (
                <Oval
                  color={theme?.colors?.white}
                  secondaryColor={theme.colors?.layer2}
                  height={30}
                  width={30}
                  strokeWidth={4}
                  ariaLabel="loading"
                />
              ) : (
                'Login'
              )}
            </Button>
          </ButtonView>
        </FormView>

        <RegisterView>
          <span>Don’t have a Billboxx account? </span>
          <Link to="/signup" aria-label="Signup">
            Create Now
          </Link>
        </RegisterView>
      </LoginLeftView>

      <Auth2FAModal showModal={show2faModal} setShowModal={setShow2faModal} loginData={loginData} />
    </LoginView>
  );
};

export default Login;
