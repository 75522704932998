import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import MobileSidebar from '@/components/MobileSidebar';
import ProfileDropdown from '@/components/ProfileDropdown';
import TableLoader from '@/components/TableLoader';
import withAuthentication from '@/hooks/withAuthentication';
import { fetchCompany, fetchUser } from '@/services/userApi';
import { numberWithCommas, numberWithCommas2 } from '@/utils/helpers';
import { ReceivablesStyles } from './invoiceDiscounting.styles';
import Requests from './components/requests/Requests';
import { fetchInvoiceDiscountMetrics } from '@/services/financingApi';
import Offers from './components/offers/Offers';
import Accepted from './components/accepted/Accepted';
import Disbursed from './components/disbursed/Disbursed';
import Closed from './components/closed/Closed';

function InvoiceDiscounting() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = secureLocalStorage.getItem('company');
  const tab = searchParams.get('tab');
  const [openNav, setOpenNav] = useState(false);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const requestMetrics = useQuery({
    queryKey: ['invoice-discount-metrics'],
    queryFn: fetchInvoiceDiscountMetrics,
    enabled: user?.data?.data?.complianceVerified || false
  });

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <ReceivablesStyles>
        <div className="receivables-header">
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{company?.data?.data?.name}</h3>
          </div>
          <ProfileDropdown />
        </div>
        <div className="receivables-wrapper">
          <div className="receivables-wrapper__page-title">
            <button type="button" onClick={() => navigate(-1)}>
              <BackIcon />
            </button>
            <h3>Invoice Discounting</h3>
          </div>

          <div className="receivables-wrapper__tabs">
            <div
              className={`tab ${tab === 'requests' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=requests&page=1&limit=10')}>
              <h3>Requests</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(requestMetrics?.data?.data?.request?.count || '0')} Items
                </h4>
                <p>₦{numberWithCommas(requestMetrics?.data?.data?.request?.sum || '00.00')}</p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab offers ${tab === 'offers' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=offers&page=1&limit=10')}>
              <h3>Offers</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(requestMetrics?.data?.data?.offers?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(requestMetrics?.data?.data?.offers?.sum || '00.00')}</p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab accepted ${tab === 'accepted' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=accepted&page=1&limit=10')}>
              <h3>Accepted</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(requestMetrics?.data?.data?.accepted?.count || '0')} Items
                </h4>
                <p>₦{numberWithCommas(requestMetrics?.data?.data?.accepted?.sum || '00.00')}</p>
              </div>
            </div>

            <div className="line"></div>
            <div
              className={`tab disbursed ${tab === 'disbursed' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=disbursed&page=1&limit=10')}>
              <h3>Disbursed</h3>
              <div className="tab-values">
                <h4>
                  {numberWithCommas2(requestMetrics?.data?.data?.disbursed?.count || '0')} Items
                </h4>
                <p>₦{numberWithCommas(requestMetrics?.data?.data?.disbursed?.sum || '00.00')}</p>
              </div>
            </div>
            <div className="line"></div>
            <div
              className={`tab closed ${tab === 'closed' ? 'active' : ''}`}
              onClick={() => setSearchParams('tab=closed&page=1&limit=10')}>
              <h3>Closed</h3>
              <div className="tab-values">
                <h4>{numberWithCommas2(requestMetrics?.data?.data?.closed?.count || '0')} Items</h4>
                <p>₦{numberWithCommas(requestMetrics?.data?.data?.closed?.sum || '00.00')}</p>
              </div>
            </div>
          </div>

          <div className="receivables-wrapper__components">
            {requestMetrics?.isFetching ? (
              <TableLoader />
            ) : tab === 'requests' ? (
              <Requests
                enabledFetch={user?.data?.data?.complianceVerified || false}
                metrics={requestMetrics?.data?.data?.request}
              />
            ) : tab === 'offers' ? (
              <Offers
                enabledFetch={user?.data?.data?.complianceVerified || false}
                metrics={requestMetrics?.data?.data?.offers}
              />
            ) : tab === 'accepted' ? (
              <Accepted
                enabledFetch={user?.data?.data?.complianceVerified || false}
                metrics={requestMetrics?.data?.data?.accepted}
              />
            ) : tab === 'disbursed' ? (
              <Disbursed
                enabledFetch={user?.data?.data?.complianceVerified || false}
                metrics={requestMetrics?.data?.data?.disbursed}
              />
            ) : (
              <Closed
                enabledFetch={user?.data?.data?.complianceVerified || false}
                metrics={requestMetrics?.data?.data?.closed}
              />
            )}
          </div>
        </div>
      </ReceivablesStyles>
    </>
  );
}

export default withAuthentication(InvoiceDiscounting);
