/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import PropTypes from 'prop-types';
import { Oval } from 'react-loader-spinner';
import fileDownload from 'js-file-download';
import axios from 'axios';

import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/nav-arrow-down.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/icons/download.icon.svg';
import useOnClickOutside from '@/hooks/useClickOutside';
import { numberWithCommas } from '@/utils/helpers';
import PDFViewer from '@/components/PDFViewer';
import { setPaymentInfo } from '@/redux/features/app.slice';
import { approveBill, deleteBill, duplicateBill, fetchPaymentPDF } from '@/services/billsApi';
import ModalLoader from '@/components/ModalLoader';
import MarkBillAsPaid from './MarkBillAsPaid';
import { device } from '@/constants/breakpoints';
import PromptModal from '@/components/PromptModal';
import { requestApproval } from '@/services/receivablesApi';
import BillInfoModal from './BillInfoModal';
import useUser from '@/hooks/useUser';
import CreatePinModal from '@/components/CreatePinModal';

function BillDetails({ showModal, setShowModal, bill, setShowEditModal }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useUser();
  const queryClient = useQueryClient();
  const [showActions, setShowActions] = useState(false);
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState(false);
  const [showBillInfoModal, setShowBillInfoModal] = useState(false);
  const [showCreatePinModal, setShowCreatePinModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteTypeModal, setShowDeleteTypeModal] = useState(false);
  const [deleteType, setDeleteType] = useState('single');
  const [loadingPaymentAdvice, setLoadingPaymentAdvice] = useState(false);
  const [saveAs, setSaveAs] = useState('pdf');
  const [showShareOptions, setShowShareOptions] = useState(false);
  const shareRef = useRef();
  const ref = useRef();
  useOnClickOutside(ref, () => setShowActions(false));
  useOnClickOutside(shareRef, () => setShowShareOptions(false));

  const fetchPaymentAdvice = useQuery({
    queryKey: ['paymentAdvice', bill?.billOrInvoiceNumber],
    queryFn: fetchPaymentPDF,
    enabled: bill?.status === 'paid'
  });

  const handleFileDownload2 = (url, filename) => {
    setLoadingPaymentAdvice(true);
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        setLoadingPaymentAdvice(false);
        fileDownload(res.data, newFileName);
      })
      .catch(() => {
        setLoadingPaymentAdvice(false);
      });
  };

  const handleFileDownload = (url, filename) => {
    const parts = filename.split('/');
    const newFileName = parts[parts.length - 1];
    axios
      .get(url, {
        responseType: 'blob'
      })
      .then((res) => {
        fileDownload(res.data, newFileName);
      });
  };

  const handleApproveBill = useMutation(({ id, data }) => approveBill(id, data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      navigate('/make-payments/bills?tab=ready');
      toast.success('Bill approved successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleRequestApproval = useMutation(requestApproval, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      navigate('/make-payments/bills?tab=approval');
      toast.success('Approval request sent successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDeleteBill = useMutation(({ id, type }) => deleteBill(id, type), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      toast.success('Bill deleted successfully');
      setShowDeleteModal(false);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDuplicateBill = useMutation(({ id }) => duplicateBill(id), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      navigate('/make-payments/bills?tab=draft');
      toast.success('Bill duplicated successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay">
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={(e) => e.stopPropagation()}>
            {/* ======== Content ======== */}
            {fetchPaymentAdvice.isFetching ? (
              <LoaderView>
                <Oval color="#92c22c" height={50} width={50} />
              </LoaderView>
            ) : (
              <div className="modal-content">
                <div className="header">
                  <span></span>
                  <button type="button" onClick={() => setShowModal(false)}>
                    <CloseIcon />
                  </button>
                </div>
                <div className="modal-content__title-view">
                  <h3>Bill Details</h3>
                </div>
                <div className="modal-content__values">
                  <div className="modal-content__values-item">
                    <span>Vendor Name</span>
                    <h3>{bill?.vendor?.companyName}</h3>
                  </div>
                  <div className="modal-content__values-item">
                    <span>Amount</span>
                    <h3>₦{numberWithCommas(bill?.amount || '00.00')}</h3>
                  </div>
                  <div className="modal-content__values-item">
                    <span>Due Date</span>
                    <h3>{format(new Date(bill?.dueDate), 'do MMM, yyyy')}</h3>
                  </div>
                  <div className="modal-content__values-item">
                    <span>Bill no.</span>
                    <h3>{bill?.billOrInvoiceNumber}</h3>
                  </div>
                </div>
                <div className="modal-content__row">
                  <div className="file-view">
                    {bill?.fileUrl?.includes('.pdf') ? (
                      <div className="pdf-view">
                        <PDFViewer file={bill?.fileUrl} />
                      </div>
                    ) : (
                      <div className="image-view">
                        {bill?.fileUrl ? (
                          <img src={bill?.fileUrl} alt="bill" />
                        ) : (
                          <p>No File to display</p>
                        )}
                      </div>
                    )}

                    {bill?.fileUrl && (
                      <div className="download-view">
                        <button
                          onClick={() => {
                            handleFileDownload(bill?.fileUrl, bill?.fileUrl);
                          }}>
                          Download Bill <DownloadIcon />
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="action-view">
                    <div className="balance">
                      <span>Balace due</span>
                      <h3>₦{numberWithCommas(bill?.balance || '00.00')}</h3>
                    </div>

                    <div className="action">
                      <button
                        type="button"
                        onClick={(e) => {
                          setShowActions(true);
                          e.stopPropagation();
                        }}>
                        Action <DownIcon />
                      </button>
                      <AnimatePresence>
                        {showActions ? (
                          <motion.div
                            className="menu-actions"
                            ref={ref}
                            initial={{ y: -20 }}
                            animate={{ y: 0 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}>
                            {handleApproveBill.isLoading && (
                              <ModalLoader message="Approving bill..." />
                            )}
                            {bill?.status === 'ready' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(setPaymentInfo(bill));
                                    if (user?.hasTransactionPin) {
                                      navigate(`/make-payments/bills/pay?id=${bill?.id}`);
                                    } else {
                                      setShowCreatePinModal(true);
                                    }
                                  }}>
                                  Make Payment
                                </button>
                                {/* {!bill?.connectionId && (
                                )} */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowMarkAsPaidModal(true);
                                  }}>
                                  Mark as paid
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDuplicateBill.mutate({
                                      id: bill?.id
                                    });
                                  }}>
                                  Duplicate bill
                                </button>
                                {bill?.isOrigin && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowDeleteTypeModal(true);
                                    }}>
                                    Delete bill
                                  </button>
                                )}
                                {/* <button type="button" onClick={() => setShowModal(false)}>
                                Cancel
                              </button> */}
                              </>
                            )}
                            {bill?.status === 'partially-paid' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    dispatch(setPaymentInfo(bill));
                                    if (user?.hasTransactionPin) {
                                      navigate(`/make-payments/bills/pay?id=${bill?.id}`);
                                    } else {
                                      setShowCreatePinModal(true);
                                    }
                                  }}>
                                  Make Payment
                                </button>
                                {/* {!bill?.connectionId && (
                                )} */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowMarkAsPaidModal(true);
                                  }}>
                                  Mark as paid
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDuplicateBill.mutate({
                                      id: bill?.id
                                    });
                                  }}>
                                  Duplicate bill
                                </button>
                                {/* <button type="button" onClick={() => setShowModal(false)}>
                                Cancel
                              </button> */}
                              </>
                            )}
                            {bill?.status === 'paid' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDuplicateBill.mutate({
                                      id: bill?.id
                                    });
                                  }}>
                                  Duplicate bill
                                </button>
                                <button type="button" onClick={() => setShowModal(false)}>
                                  Cancel
                                </button>
                              </>
                            )}
                            {bill?.status === 'pending-approval' && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowBillInfoModal(true);
                                  }}>
                                  Approve bill
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDuplicateBill.mutate({
                                      id: bill?.id
                                    });
                                  }}>
                                  Duplicate bill
                                </button>
                                {handleApproveBill.isLoading && (
                                  <ModalLoader message="Approving bill..." />
                                )}
                                {bill?.isOrigin && (
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setShowEditModal(true);
                                      e.stopPropagation();
                                    }}>
                                    Edit bill
                                  </button>
                                )}
                                {bill?.isOrigin && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowDeleteTypeModal(true);
                                    }}>
                                    Delete bill
                                  </button>
                                )}
                                <button type="button" onClick={() => setShowModal(false)}>
                                  Cancel
                                </button>
                              </>
                            )}
                            {bill?.status === 'draft' && (
                              <>
                                {/* <button
                                type="button"
                                onClick={() => {
                                  handleApproveBill.mutate({
                                    id: bill?.id,
                                    data: { action: 'approve' }
                                  });
                                }}>
                                Approve bill
                              </button> */}
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleRequestApproval.mutate({ billOrInvoiceId: bill?.id });
                                  }}>
                                  Request approval
                                </button>

                                <button
                                  type="button"
                                  onClick={() => {
                                    handleDuplicateBill.mutate({
                                      id: bill?.id
                                    });
                                  }}>
                                  Duplicate bill
                                </button>
                                {bill?.isOrigin && (
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      setShowEditModal(true);
                                      e.stopPropagation();
                                    }}>
                                    Edit bill
                                  </button>
                                )}
                                <button type="button" onClick={() => setShowModal(false)}>
                                  Cancel
                                </button>
                                {bill?.isOrigin && (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowDeleteModal(true);
                                    }}>
                                    Delete bill
                                  </button>
                                )}
                              </>
                            )}
                          </motion.div>
                        ) : null}
                      </AnimatePresence>
                    </div>

                    {bill?.status === 'draft' && (
                      <div className="request">
                        <button
                          type="button"
                          onClick={() => {
                            handleRequestApproval.mutate({ billOrInvoiceId: bill?.id });
                          }}>
                          Request approval
                        </button>
                      </div>
                    )}
                    {bill?.status === 'ready' && (
                      <div
                        className="request"
                        onClick={() => {
                          dispatch(setPaymentInfo(bill));
                          if (user?.hasTransactionPin) {
                            navigate(`/make-payments/bills/pay?id=${bill?.id}`);
                          } else {
                            setShowCreatePinModal(true);
                          }
                        }}>
                        <button type="button">Make Payment</button>
                      </div>
                    )}
                    {bill?.status === 'partially-paid' && (
                      <div className="request">
                        <button
                          type="button"
                          className="request-btn"
                          onClick={() => {
                            dispatch(setPaymentInfo(bill));
                            if (user?.hasTransactionPin) {
                              navigate(`/make-payments/bills/pay?id=${bill?.id}`);
                            } else {
                              setShowCreatePinModal(true);
                            }
                          }}>
                          Make Payment
                        </button>
                      </div>
                    )}

                    {bill?.status === 'paid' && (
                      <div className="request">
                        <button
                          type="button"
                          className="request-btn"
                          onClick={() => setShowShareOptions(true)}>
                          Download Advice
                        </button>
                        <AnimatePresence>
                          {showShareOptions && (
                            <motion.div
                              className="menu-actionss"
                              ref={shareRef}
                              initial={{ y: 20 }}
                              animate={{ y: 0 }}
                              exit={{ y: -20, opacity: 0 }}
                              transition={{ duration: 0.2 }}>
                              <button
                                className="action"
                                onClick={() => {
                                  setSaveAs('png');
                                  handleFileDownload2(
                                    fetchPaymentAdvice?.data?.data?.url.replace('.pdf', '.png'),
                                    `payment-advice-${bill?.billOrInvoiceNumber}.png`
                                  );
                                }}>
                                {loadingPaymentAdvice && saveAs === 'png'
                                  ? 'Downloading...'
                                  : ' Download Image'}
                              </button>
                              <button
                                className="action"
                                onClick={() => {
                                  setSaveAs('pdf');
                                  handleFileDownload2(
                                    fetchPaymentAdvice?.data?.data?.url,
                                    `payment-advice-${bill?.billOrInvoiceNumber}.pdf`
                                  );
                                }}>
                                {loadingPaymentAdvice && saveAs === 'pdf'
                                  ? 'Downloading...'
                                  : ' Download PDF'}
                              </button>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </div>
                    )}

                    {bill?.status === 'pending-approval' && (
                      <div className="request">
                        <button
                          type="button"
                          onClick={() => {
                            setShowBillInfoModal(true);
                          }}>
                          Approve bill
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </motion.div>
        </ModalView>
      )}

      <MarkBillAsPaid
        showModal={showMarkAsPaidModal}
        setShowModal={setShowMarkAsPaidModal}
        bill={bill}
      />

      <BillInfoModal
        showModal={showBillInfoModal}
        setShowModal={setShowBillInfoModal}
        bill={bill}
      />

      <CreatePinModal
        showModal={showCreatePinModal}
        setShowModal={setShowCreatePinModal}
        billId={bill?.id}
      />

      {handleApproveBill.isLoading && <ModalLoader message="Approving bill..." />}
      {handleDuplicateBill.isLoading && <ModalLoader message="Duplicating bill..." />}
      {handleRequestApproval.isLoading && <ModalLoader message="Requesting approval..." />}

      {/* Delete type */}
      <PromptModal showModal={showDeleteTypeModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteTypeModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <div className="question-icon">
            <span>?</span>
          </div>
          <h3>Select an option</h3>
          <div className="row">
            <button
              type="button"
              className="primary"
              onClick={() => {
                setDeleteType('single');
                setShowDeleteModal(true);
              }}>
              Delete for me
            </button>
            <button
              type="button"
              className="secondary"
              onClick={() => {
                setDeleteType('all');
                setShowDeleteModal(true);
              }}>
              Delete for everyone
            </button>
          </div>
        </div>
      </PromptModal>

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete bill</h3>
          <p>
            Are you sure you want to delete this bill? When you delete a bill, it will be removed
            from the list and placed in the archive.
          </p>
          <button
            type="button"
            className="delete"
            onClick={() =>
              handleDeleteBill.mutate({
                id: bill?.id,
                type: deleteType
              })
            }>
            {handleDeleteBill.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </AnimatePresence>
  );
}

export default BillDetails;

BillDetails.propTypes = {
  bill: PropTypes.object,
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  setShowEditModal: PropTypes.func
};

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 9000;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal {
    background-color: ${(props) => props.theme.colors?.white};
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 60%;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 20px;
    border-radius: 30px;
    max-height: 90%;
    overflow-y: scroll;

    @media ${device.phone} {
      width: calc(100% - 32px);
      border-radius: 16px;
    }

    .modal-content {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin: 0;
      justify-content: flex-start;
      flex-direction: column;
      padding: 30px;

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media ${device.phone} {
          width: 100%;
        }
      }

      .modal-content__title-view {
        width: 100%;
        margin-top: 20px;

        h3 {
          font-size: 1.25rem;
          font-weight: 600;
          color: ${(props) => props.theme.colors?.secondary};
        }
      }

      .modal-content__values {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column;
        }

        .modal-content__values-item {
          width: 100%;

          @media ${device.phone} {
            width: 100%;
            margin-bottom: 20px;
          }

          span {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
          }

          h3 {
            font-size: 1.25rem;
            font-weight: 600;
            color: ${(props) => props.theme.colors?.secondary};
            margin-top: 8px;
          }
        }
      }

      .modal-content__row {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 30px;

        @media ${device.phone} {
          width: 100%;
          flex-direction: column-reverse;
        }

        .file-view {
          width: 55%;

          @media ${device.phone} {
            width: 100%;
          }

          .download-view {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 20px;

            button {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              background: transparent;
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.secondary};
              font-weight: 500;
            }
          }

          .pdf-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;
            overflow: scroll;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            .react-pdf__Page {
              max-width: calc(100% - 1em);
              box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
              margin: 1em;
            }

            .react-pdf__Page canvas {
              max-width: 100%;
              height: auto !important;
            }

            .react-pdf__message {
              padding: 1em;
              color: white;
            }
          }

          .image-view {
            width: 100%;
            height: 450px;
            border: 1px solid #e0e0e0;

            @media ${device.phone} {
              width: 100%;
              height: 400px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }

            p {
              padding: 20px;
              text-align: center;
              color: ${(props) => props.theme.colors?.secondary};
            }

            .refresh {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              p {
                padding: 20px;
                text-align: center;
                color: ${(props) => props.theme.colors?.secondary};
              }

              button {
                color: ${(props) => props.theme.colors?.secondary};
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  margin-right: 5px;

                  path {
                    stroke: ${(props) => props.theme.colors?.secondary};
                  }
                }
              }
            }
          }
        }

        .action-view {
          width: 40%;
          height: 450px;
          position: relative;

          @media ${device.phone} {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
          }

          .balance {
            width: 100%;

            span {
              font-size: 0.875rem;
              font-weight: 400;
              color: ${(props) => props.theme.colors?.secondary};
            }

            h3 {
              font-size: 1.25rem;
              font-weight: 600;
              color: ${(props) => props.theme.colors?.secondary};
              margin-top: 8px;
            }
          }

          .action {
            width: 100%;
            margin-top: 30px;
            position: relative;

            button {
              width: 120px;
              height: 40px;
              background: ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              border: none;
              color: ${(props) => props.theme.colors?.white};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                margin-left: 10px;

                path {
                  stroke: ${(props) => props.theme.colors?.white};
                }
              }
            }

            .menu-actions {
              position: absolute;
              top: 45px;
              left: 0;
              background-color: ${(props) => props.theme.colors?.white};
              border-radius: 6px;
              box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
              width: 180px;
              z-index: 100;
              overflow: hidden;

              button {
                width: 100%;
                padding: 16px 24px;
                border: none;
                background-color: ${(props) => props.theme.colors?.white};
                font-size: 0.875rem;
                color: ${(props) => props.theme.colors?.secondary};
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:hover {
                  background-color: ${(props) => props.theme.colors?.layer2};
                }
              }
            }
          }

          .request {
            width: 100%;
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media ${device.phone} {
              width: 100%;
            }

            button {
              width: 180px;
              height: 50px;
              background: ${(props) => props.theme.colors?.white};
              border: 1px solid ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.primary};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              @media ${device.phone} {
                width: 48%;
              }
            }

            .download {
              width: 180px;
              height: 50px;
              background: ${(props) => props.theme.colors?.white};
              border: 1px solid ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.primary};
              font-size: 0.875rem;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;

              @media ${device.phone} {
                width: 48%;
                font-size: 0.8rem;
              }
            }

            .request-btn {
              width: 180px;
              height: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: ${(props) => props.theme.colors?.primary};
              border-radius: 8px;
              color: ${(props) => props.theme.colors?.white};
              font-weight: 500;

              @media ${device.phone} {
                width: 48%;
              }
            }

            .menu-actionss {
              position: absolute;
              top: -80px;
              left: 0;
              background-color: ${(props) => props.theme.colors?.white};
              border-radius: 6px;
              box-shadow: 0px 11.3975px 34.1925px rgba(0, 0, 0, 0.08);
              width: 180px;
              z-index: 100;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;

              .action {
                width: 100%;
                padding: 0 20px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 0;
                height: 40px;
                background-color: ${(props) => props.theme.colors?.white};
                border: none;
                color: ${(props) => props.theme.colors?.secondary};
                font-weight: 500;

                &:hover {
                  background-color: ${(props) => props.theme.colors?.layer};
                }
              }
            }
          }
        }
      }
    }
  }
`;

const LoaderView = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: center;
`;
