/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import React, { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import ProfileDropdown from '@/components/ProfileDropdown';
import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as SearchIcon } from '@/assets/icons/search.icon.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/fileUpload.icon.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.icon.svg';
import FetchLoader from '@/components/FetchLoader';
import Pagination from '@/components/Pagination';
import useDebounce from '@/hooks/useDebounce';
import ExportPaymentReport from './components/ExportPaymentReport';
import InflowsTables from './components/InflowTable';
import { fetchInflows } from '@/services/receivablesApi';
import {
  InflowsView,
  ComponentsView,
  ContainerHeaderView,
  ContainerView,
  HeaderView,
  PageTitleView,
  SearchView,
  PaginationView
} from './inflows.styles';
import MobileSidebar from '@/components/MobileSidebar';
import withAuthentication from '@/hooks/withAuthentication';
import { useSearchParams } from 'react-router-dom';
import SortingComponent from '@/components/SortingComponent';
import FilterSort from '@/components/FilterSort';
import secureLocalStorage from 'react-secure-storage';
import { fetchCompany } from '@/services/userApi';
import { truncate } from '@/utils/helpers';
import TableRowsSelect from '@/components/TableRowSelect';

function Inflows() {
  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = secureLocalStorage.getItem('company');
  const limit = searchParams.get('limit');
  const currentPage = searchParams.get('page');
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('none');
  const [filter, setFilter] = useState('none');
  const [showExportModal, setShowExportModal] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const debouncedsearch = useDebounce(search, 500);

  const handleFilterApply = useCallback(({ filter }) => {
    setFilter(filter);
  }, []);

  const inflows = useQuery({
    queryKey: [
      'inflows',
      {
        page: currentPage || 1,
        pageLimit: limit || 10,
        search: debouncedsearch?.trim(),
        sortBy: sortOrder,
        fetch: filter
      }
    ],
    queryFn: fetchInflows
  });

  const company = useQuery({
    queryKey: ['company', companyId],
    queryFn: fetchCompany
  });

  const handleRowsPerPageChange = (value) => {
    setSearchParams(`page=1&limit=${value}`);
  };

  const onPageChange = (page) => {
    setSearchParams(`page=${page}&limit=${limit || 10}`);
  };

  return (
    <>
      <MobileSidebar openNav={openNav} setOpenNav={setOpenNav} />
      <InflowsView>
        <HeaderView>
          <div className="title-view">
            <button type="button" className="menu" onClick={() => setOpenNav(true)}>
              <MenuIcon />
            </button>
            <h3>{truncate(company?.data?.data?.name || '', 40, true)}</h3>
          </div>
          <ProfileDropdown />
        </HeaderView>
        <ContainerView>
          <PageTitleView>
            <button type="button">
              <BackIcon />
            </button>
            <h3>Get paid</h3>
          </PageTitleView>

          <ContainerHeaderView>
            <h3>Inflows</h3>
            <div className="left-view">
              <SearchView>
                <input
                  type="search"
                  placeholder="Search inflows"
                  onChange={(e) => setSearch(e.target.value)}
                />
                <SearchIcon className="search-icon" />
              </SearchView>
              <SortingComponent sortOrder={sortOrder} onSortChange={setSortOrder} />
              <FilterSort onApply={handleFilterApply} />
              <button type="button" className="upload" onClick={() => setShowExportModal(true)}>
                <FileIcon />
              </button>
            </div>
          </ContainerHeaderView>

          <ComponentsView>
            {inflows?.isLoading ? <FetchLoader /> : <InflowsTables inflows={inflows?.data?.data} />}
          </ComponentsView>
          <PaginationView>
            <Pagination
              itemsPerPage={limit || 10}
              totalItems={inflows?.data?.data?.meta?.totalCount}
              currentPage={inflows?.data?.data?.meta?.page}
              onPageChange={onPageChange}
            />

            <TableRowsSelect onChange={handleRowsPerPageChange} />
          </PaginationView>
        </ContainerView>
      </InflowsView>

      <ExportPaymentReport showModal={showExportModal} setShowModal={setShowExportModal} />
    </>
  );
}

export default withAuthentication(Inflows);
