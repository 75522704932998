/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

import { ReactComponent as AddIcon } from '@/assets/icons/add.icon.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.icon.svg';
import { ReactComponent as WarnIcon } from '@/assets/icons/warn.icon.svg';
// import { ReactComponent as InfoIcon } from '@/assets/icons/info.icon.svg';
import BankLogo from '@/assets/images/BankLogo.png';
import Button from '@/components/Button/Button';
import PromptModal from '@/components/PromptModal';
import { device } from '@/constants/breakpoints';
import {
  deleteBankAccount,
  fetchBankList,
  fetchUserBankAccounts,
  setDefaultBankAccount
} from '@/services/bankAccountApi';
import { fetchUser } from '@/services/userApi';
import toast from 'react-hot-toast';
import AddBankAccountModal from '../../Settings/components/AddBankAccountModal';

function BankAccount() {
  const queryClient = useQueryClient();
  const [clickedIndex, setClickedIndex] = useState(-1);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const user = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser
  });

  const banks = useQuery({
    queryKey: ['banks'],
    queryFn: fetchBankList
  });

  const bankAccounts = useQuery({
    queryKey: ['bankAccounts'],
    queryFn: fetchUserBankAccounts
  });

  const copyAccountDetails = (bankName, accountNumber, accountName) => {
    const text = `${bankName?.toUpperCase()} \n${accountNumber}\n${accountName}`;
    navigator.clipboard.writeText(text);
    toast.success('Account details copied to clipboard');
  };

  const handleDeleteBankAccount = useMutation({
    mutationFn: ({ id }) => deleteBankAccount(id),
    onSuccess: (data) => {
      queryClient.invalidateQueries(['bankAccounts']);
      setShowDeleteModal(false);
      toast.success('Bank account deleted successfully');
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    }
  });

  return (
    <BankAccountView>
      {user?.isFetching || banks?.isFetching || bankAccounts?.isFetching ? (
        <div className="loader-view">
          <div className="loader">
            <Oval
              color="#92C22C"
              secondaryColor="#ddd"
              height={50}
              width={50}
              strokeWidth={4}
              ariaLabel="loading"
            />
          </div>
        </div>
      ) : (
        <div className="accounts">
          <div className="flex">
            <p className="text">Bank Account</p>

            <Button className="add-account" onClick={() => setShowAddModal(true)}>
              <AddIcon />
              <p>Add new</p>
            </Button>
          </div>
          <div className="account-container">
            {bankAccounts?.data?.data?.map((account, index) => (
              <div className="account" key={account?.id}>
                {account?.isDefault && <span className="default">Default</span>}
                <div className="account-info">
                  <div className="img-container">
                    <img src={BankLogo} alt="Bank Logo" />
                  </div>
                  <h3>{account?.bankName}</h3>
                  <h4>{account?.accountName}</h4>
                  <p>{account?.accountNumber}</p>
                </div>

                <div className="hover">
                  <Button
                    className="default-btn"
                    type="button"
                    onClick={() => {
                      copyAccountDetails(account?.bankName, account?.accountNumber, account?.accountName);
                    }}>
                    Copy details
                  </Button>

                  {(!account?.isDefault && !account?.isInternal) && (
                    <Button
                      type="button"
                      className="default-btn"
                      onClick={() => {
                        setClickedIndex(index);
                        setSelectedAccount(account);
                        setShowDeleteModal(true);
                      }}>
                      {handleDeleteBankAccount?.isLoading && clickedIndex === index ? (
                        <Oval
                          color="#fff"
                          secondaryColor="#eee"
                          width={20}
                          height={20}
                          strokeWidth={3}
                          ariaLabel="loader"
                        />
                      ) : (
                        'Delete'
                      )}
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <AddBankAccountModal
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        shouldNavigate={false}
        url={null}
      />

      <PromptModal showModal={showDeleteModal}>
        <div className="header">
          <button type="button" onClick={() => setShowDeleteModal(false)}>
            <CloseIcon />
          </button>
        </div>
        <div className="content">
          <WarnIcon />
          <h3>Delete Bank Account</h3>
          <p>Do you really want to delete this bank account?</p>
          <button
            type="button"
            className="delete"
            onClick={() => handleDeleteBankAccount.mutate({ id: selectedAccount?.id })}>
            {handleDeleteBankAccount.isLoading ? (
              <Oval color="#fff" secondaryColor="#ddd" width={30} height={30} strokeWidth={4} />
            ) : (
              'Delete'
            )}
          </button>
        </div>
      </PromptModal>
    </BankAccountView>
  );
}

export default BankAccount;

const BankAccountView = styled.div`
  width: 100%;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
  }

  .loader-view {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .accounts {
    width: 100%;
    padding: 0 30px;

    @media ${device.phone} {
      width: 100%;
      padding: 0;
    }

    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;

      .text {
        font-size: 24px;
        font-weight: 500;
        color: ${(props) => props.theme.colors?.secondary};
      }

      .add-account {
        width: 22%;
        height: 2.75rem;
        border-radius: 0.45rem;
        padding: 1rem 0;
        gap: 0.45rem;
        color: ${(props) => props.theme.colors?.white};

        p {
          font-weight: 600;
          font-size: 11.612px;
          letter-spacing: 0.287px;
        }
      }

      @media ${device.phone} {
        .text {
          font-size: 20px;
        }

        .add-account {
          width: 40%;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      gap: 1rem;
      background: ${(props) => props.theme.colors?.warning_200};
      border-radius: 8px;
      padding: 1rem 1.5rem;
      margin-bottom: 20px;

      @media ${device.phone} {
        padding: 0.5rem 1rem;
        gap: 0.5rem;
      }

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        color: ${(props) => props.theme.colors?.secondary};
      }
    }

    .account-container {
      display: flex;
      gap: 1rem;

      @media ${device.phone} {
        flex-direction: column;
      }

      .account {
        width: 32%;
        display: flex;
        justify-content: center;
        align-items: end;
        flex-direction: column;
        background: #f8f8f8;
        border-radius: 8px;
        cursor: pointer;
        padding: 20px;
        height: 220px;
        position: relative;

        @media ${device.phone} {
          width: 100%;
          margin-bottom: 20px;
          padding: 16px;
        }

        .hover {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: rgba(0, 0, 0, 0.49);
          opacity: 0;
          transition: opacity 0.8s;

          &:hover {
            opacity: 1;
          }
        }

        .default {
          width: 59.054px;
          position: absolute;
          right: 0;
          top: 0;
          background-color: ${({ theme }) => theme.colors?.primary};
          font-size: 10.737px;
          font-weight: 500;
          padding: 8px 10px;
          border-radius: 0px 8.42px 0px 8.42px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: ${(props) => props.theme.colors?.white};

          @media ${device.phone} {
            font-size: 10px;
            font-weight: 500;
            padding: 6px 17px;
            top: 0;
          }
        }

        .account-info {
          width: 100%;

          .img-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1.75rem;
            margin: 1rem 0;
            width: 46px;
            height: 46px;
            border-radius: 31px;
            background-color: #dcedb7;

            img {
              width: 38.001px;
              height: 25px;
              object-fit: cover;
            }
          }

          h3 {
            font-size: 1rem;
            font-weight: 500;
            color: ${(props) => props.theme.colors?.secondary};
            margin-bottom: 10px;
          }

          h4 {
            font-size: 1rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
            margin-bottom: 10px;
          }

          p {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${(props) => props.theme.colors?.secondary};
          }
        }

        .default-btn {
          width: 120px;
          height: 30px;
          padding: 10px;
          margin: 1rem 0;
          font-size: 10px;
          border-radius: 0.45rem;

          @media ${device.phone} {
            height: 40px;
            border-radius: 0.45rem;
            font-size: 12px;
          }
        }
      }
    }

    .add-view {
      width: 220px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f1f1f1;
      border-radius: 8px;
      cursor: pointer;

      svg {
        margin-right: 10px;
      }

      span {
        color: ${(props) => props.theme.colors?.secondary};
      }
    }
  }
`;
