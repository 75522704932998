/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Oval } from 'react-loader-spinner';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { ReactComponent as BackIcon } from '@/assets/icons/back.icon.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/plus.icon.svg';
import { createBill } from '@/services/billsApi';
import PDFViewer from '@/components/PDFViewer';
import TextArea from '@/components/TextArea/TextArea';
import Input from '@/components/Input/Input';
import CurrencyyInput from '@/components/CurrencyInput';
import { createVendor, deleteVendor, fetchVendors } from '@/services/vendorApi';
import DropArea from './DropArea';
import SearchDropdown2 from '@/components/SearchDropdown2';
import NewVendorModal from '@/pages/Vendors/components/NewVendorModal';
import { device } from '@/constants/breakpoints';
import SearchDropdown from '@/components/SearchDropdown';
import withAuthentication from '@/hooks/withAuthentication';
import FindVendorModal from './billFinancing/FindVendor';

const billFrequency = [
  { id: 1, name: 'One time', value: 'one-time' },
  { id: 1, name: 'Daily', value: 'daily' },
  { id: 2, name: 'Weekly', value: 'weekly' },
  { id: 3, name: 'Monthly', value: 'monthly' },
  { id: 3, name: 'Quarterly', value: 'quarterly' },
  { id: 3, name: 'Yearly', value: 'yearly' }
];

function FileExtract() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [, setShowUploadButton] = useState(true);
  const [file, setFile] = useState(null);
  const [extractedFile, setExtractedFile] = useState(null);
  const [showVendorForm, setShowVendorForm] = useState(false);
  const [showFindVendorModal, setShowFindVendorModal] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [statusType, setStatusType] = useState('draft');
  const [vendorName, setVendorName] = useState('');
  const [, setExtractedVendorName] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [frequency, setFrequency] = useState(billFrequency[0]);
  const [expires, setExpires] = useState(false);

  const schema = yup
    .object({
      recurringStartDate: (() => {
        let validation = yup.string();
        if (frequency?.value !== 'one-time') {
          validation = validation.required('Start date is required');
        }
        return validation;
      })(),
      recurringEndDate: (() => {
        let validation = yup.string();
        if (!expires && frequency?.value !== 'one-time') {
          validation = validation.required('End date is required');
        }
        return validation;
      })(),
      amount: yup.string().required('Bill amount is required'),
      dueDate: yup.string().required('Due date is required'),
      description: yup.string().nullable(),
      billOrInvoiceNumber: yup.string().required('Bill number is required'),
      email: (() => {
        let validation = yup.string();
        if (!vendor?.email) {
          validation = validation.email('Invalid email').required('email is required');
        }
        return validation;
      })()
    })
    .required();

  const vendors = useQuery({
    queryKey: ['vendors', { page: 1, pageLimit: 1000 }],
    queryFn: fetchVendors
  });

  const {
    register,
    control,
    reset,
    getValues,
    handleSubmit,

    formState: { errors, dirtyFields, isValid }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      amount: extractedFile?.totalAmount,
      billOrInvoiceNumber: extractedFile?.billOrInvoiceNumber,
      dueDate: extractedFile?.dueDate ? extractedFile?.dueDate : null,
      description: extractedFile?.description,
      email: vendor?.email || vendorEmail || ''
    }
  });

  useEffect(() => {
    reset({
      amount: extractedFile?.totalAmount,
      billOrInvoiceNumber: extractedFile?.billOrInvoiceNumber,
      dueDate: extractedFile?.dueDate ? extractedFile?.dueDate : null,
      description: extractedFile?.description,
      email: vendor?.email || vendorEmail || ''
    });
  }, [extractedFile, vendor, vendorEmail, reset]);

  const handleDeleteVendor = useMutation(deleteVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const handleDraftBill = useMutation(createBill, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      navigate('/make-payments/bills?tab=draft');
      toast.success('Bill created successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      if (vendorId) {
        handleDeleteVendor.mutate(vendorId);
      }
    }
  });

  const handleCreateBill = useMutation(createBill, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['bills'] });
      navigate('/make-payments/bills?tab=approval');
      queryClient.invalidateQueries({ queryKey: ['billsMetrics'] });
      toast.success('Bill created successfully');
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
      if (vendorId) {
        handleDeleteVendor.mutate(vendorId);
      }
    }
  });

  const handleCreateVendor = useMutation(createVendor, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['vendors'] });
      setVendorId(data?.data?.id);

      const billData = {
        vendorId: data?.data?.id,
        amount: parseFloat(getValues('amount')),
        email: getValues('email'),
        frequency: frequency?.value,
        status: statusType,
        billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
        description: getValues('description'),
        dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
        recurringStartDate: getValues('recurringStartDate')
          ? new Date(getValues('recurringStartDate')).toISOString()
          : undefined,
        recurringEndDate: getValues('recurringEndDate')
          ? new Date(getValues('recurringEndDate')).toISOString()
          : undefined,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        fileUrl: file
      };

      const filteredBillData = Object.fromEntries(
        Object.entries(billData).filter(([_, v]) => v != null && v !== '')
      );

      if (!isValid) {
        return;
      } else {
        if (statusType === 'pending-approval') {
          handleCreateBill.mutate(filteredBillData);
        } else {
          handleDraftBill.mutate(filteredBillData);
        }
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    }
  });

  const onSubmit = (status) => {
    const findVendor = vendors?.data?.data?.items?.find(
      (item) =>
        item?.companyName?.toLocaleLowerCase() === vendorName?.toLocaleLowerCase() ||
        item?.name?.toLocaleLowerCase() === vendorName?.toLocaleLowerCase()
    );

    if (!findVendor) {
      handleCreateVendor.mutate({
        companyName: vendorName,
        name: vendorName,
        type: 'business'
      });
    } else {
      const billData = {
        vendorId: findVendor?.id,
        amount: parseFloat(getValues('amount')),
        email: getValues('email'),
        frequency: frequency?.value,
        status: statusType,
        billOrInvoiceNumber: getValues('billOrInvoiceNumber'),
        description: getValues('description'),
        dueDate: getValues('dueDate') ? new Date(getValues('dueDate')).toISOString() : undefined,
        recurringStartDate: getValues('recurringStartDate')
          ? new Date(getValues('recurringStartDate')).toISOString()
          : undefined,
        recurringEndDate: getValues('recurringEndDate')
          ? new Date(getValues('recurringEndDate')).toISOString()
          : undefined,
        shouldExpireRecurring: expires ? 'no' : 'yes',
        fileUrl: file
      };

      const filteredBillData = Object.fromEntries(
        Object.entries(billData).filter(([_, v]) => v != null && v !== '')
      );

      if (!isValid) {
        return;
      } else {
        if (status === 'save') {
          handleCreateBill.mutate(filteredBillData);
        } else {
          handleDraftBill.mutate(filteredBillData);
        }
      }
    }
  };

  return (
    <ExtractView>
      <div className="back-view">
        <button
          type="button"
          className="back"
          onClick={() => {
            navigate(-1);
          }}>
          <BackIcon />
        </button>
      </div>

      {!extractedFile ? (
        <DropArea
          setExtractedFile={setExtractedFile}
          setShowUploadButton={setShowUploadButton}
          setFile={setFile}
          setVendor={setVendor}
          setVendorName={setVendorName}
          setExtractedVendorName={setExtractedVendorName}
        />
      ) : null}

      <ContainerView>
        {extractedFile ? (
          <div className="wrapper">
            <FileView>
              {extractedFile?.documentUrl.includes('.pdf') ? (
                <PDFViewer file={extractedFile?.documentUrl} />
              ) : (
                <div className="image-viewer">
                  <img src={extractedFile?.documentUrl} alt="invoice" />
                </div>
              )}
            </FileView>
            <FormView>
              <form>
                {/* Vendor */}
                <div className="input-view vendor">
                  <label htmlFor="vendor">Select Vendor</label>
                  <SearchDropdown2
                    options={vendors?.data?.data?.items}
                    id="id"
                    name="companyName"
                    setVendorId={setVendorId}
                    setVendorName={setVendorName}
                    vendorName={vendorName || vendor?.name || vendor?.companyName}
                    setVendorEmail={setVendorEmail}
                  />
                </div>

                <div className="add-view">
                  <button type="button" onClick={() => setShowFindVendorModal(true)}>
                    <PlusIcon /> Add new vendor
                  </button>
                </div>

                <div className="input-view">
                  <label htmlFor="name">Vendor email</label>

                  <Input
                    type="email"
                    {...register('email')}
                    error={errors?.email?.message}
                    placeholder="Enter vendor email"
                  />

                  {errors?.email && <span className="error-label">{errors?.email?.message}</span>}
                </div>

                {/* Bill amount */}
                <div className="input-view">
                  <label htmlFor="name">Bill amount</label>
                  <Controller
                    name="amount"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value, name } }) => (
                      <CurrencyyInput
                        name={name}
                        placeholder="Enter bill amount"
                        decimalsLimit={2}
                        allowNegativeValue={false}
                        prefix="₦"
                        onValueChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        error={!!errors?.amount?.message}
                        className={`currency-input ${
                          errors?.amount && dirtyFields?.amount
                            ? ' has-error'
                            : dirtyFields?.amount
                              ? 'valid'
                              : ''
                        }`}
                      />
                    )}
                  />

                  {errors?.amount && <span className="error-label">{errors?.amount?.message}</span>}
                </div>

                {/* Bill frequency */}
                <div className="input-view">
                  <label htmlFor="vendor">Bill Frequency</label>
                  <SearchDropdown
                    options={billFrequency}
                    id="id"
                    name="name"
                    setValue={setFrequency}
                    value={frequency}
                    placeholder="Select frequency"
                  />
                </div>

                {frequency?.value !== 'one-time' && (
                  <>
                    {/* Frequency date */}
                    <div className="date-row">
                      <div className="input-view">
                        <label htmlFor="name">Start date</label>

                        <Input
                          type="date"
                          {...register('recurringStartDate')}
                          error={errors?.recurringStartDate?.message}
                        />

                        {errors?.recurringStartDate && (
                          <span className="error-label">{errors?.recurringStartDate?.message}</span>
                        )}
                      </div>
                      <div className="input-view">
                        <label htmlFor="name">End date</label>

                        <Input
                          type="date"
                          {...register('recurringEndDate')}
                          error={errors?.recurringEndDate?.message}
                          disabled={!!expires}
                          className={expires ? 'disabled' : ''}
                        />

                        {errors?.recurringEndDate && (
                          <span className="error-label">{errors?.recurringEndDate?.message}</span>
                        )}
                      </div>
                    </div>

                    {/* Checkbox */}
                    <div className="checkbox-view">
                      <input
                        type="checkbox"
                        name="expires"
                        id="expires"
                        value="false"
                        className="checkbox"
                        checked={expires}
                        onChange={() => setExpires(!expires)}
                      />
                      <label htmlFor="expires">Never expires</label>
                    </div>
                  </>
                )}

                {/* Bill number */}
                <div className="input-view">
                  <label htmlFor="billOrInvoiceNumber">Bill (or Invoice) no.</label>
                  <Input
                    type="text"
                    {...register('billOrInvoiceNumber')}
                    error={!!errors?.billOrInvoiceNumber?.message}
                  />

                  {errors?.billOrInvoiceNumber && (
                    <span className="error-label">{errors?.billOrInvoiceNumber?.message}</span>
                  )}
                </div>

                {/* Due date */}
                <div className="input-view">
                  <label htmlFor="name">Due date</label>

                  <Input type="date" {...register('dueDate')} error={!!errors?.dueDate?.message} />

                  {errors?.dueDate && (
                    <span className="error-label">{errors?.dueDate?.message}</span>
                  )}
                </div>

                {/* Description */}
                <div className="input-view">
                  <label htmlFor="description">Description</label>
                  <TextArea
                    type="number"
                    {...register('description')}
                    error={!!errors?.description?.message}
                    placeholder="Enter description"
                  />

                  {errors?.description && (
                    <span className="error-label">{errors?.description?.message}</span>
                  )}
                </div>

                {/* Document */}
                {/* <FilePicker file={file} setFile={setFile} acceptsPDF /> */}

                <div className="cta-view">
                  <button
                    type="button"
                    className="cta-view__save"
                    onClick={() => {
                      handleSubmit(onSubmit('save'))();
                      setStatusType('pending-approval');
                    }}>
                    {handleCreateBill.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Save and request approval'
                    )}
                  </button>
                  <button
                    type="button"
                    className="cta-view__draft"
                    onClick={() => {
                      handleSubmit(onSubmit('draft'))();
                      setStatusType('draft');
                    }}>
                    {handleDraftBill.isLoading ? (
                      <Oval
                        color="#FFF"
                        secondaryColor="#ddd"
                        height={30}
                        width={30}
                        strokeWidth={4}
                        ariaLabel="loading"
                      />
                    ) : (
                      'Save'
                    )}
                  </button>
                </div>
              </form>
            </FormView>
          </div>
        ) : null}
      </ContainerView>

      <NewVendorModal
        showModal={showVendorForm}
        setShowModal={setShowVendorForm}
        setVendor={setVendor}
        setVendorName={setVendorName}
      />

      <FindVendorModal
        showModal={showFindVendorModal}
        setShowModal={setShowFindVendorModal}
        showAddNewVendor={showVendorForm}
        setShowAddNewVendor={setShowVendorForm}
        setVendor={setVendor}
        setVendorName={setVendorName}
      />
    </ExtractView>
  );
}

export default withAuthentication(FileExtract);

const ExtractView = styled.div`
  width: 1300px;
  margin: 0 auto;
  margin-top: 50px;

  @media ${device.phone} {
    width: 100%;
    margin-top: 30px;
    padding: 0 16px;
  }

  .back-view {
  }

  .upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 200px;
      height: 64px;
      background: ${({ theme }) => theme.colors?.primary};
      color: ${({ theme }) => theme.colors?.white};
      border: none;
      border-radius: 16px;
      font-weight: 600;
    }
  }

  .loader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;

    p {
      font-size: 1rem;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.activeColor};
      width: 280px;
      text-align: center;
      margin-top: 20px;
    }
  }
`;

const ContainerView = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media ${device.phone} {
    width: 100%;
    flex-direction: column;
  }

  .wrapper {
    width: 85%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;

    @media ${device.phone} {
      width: 100%;
      flex-direction: column;
    }
  }
`;

const FileView = styled.div`
  width: 48%;
  max-height: 80vh;
  padding: 10px;
  border: 1.7131px dashed #cccccc;
  overflow-y: scroll;

  @media ${device.phone} {
    width: 100%;
    height: 400px;
    margin-bottom: 20px;
  }

  .image-viewer {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const FormView = styled.div`
  width: 48%;
  height: 100%;
  display: flex;
  align-items: flex-start;

  @media ${device.phone} {
    width: 100%;
  }

  form {
    width: 100%;
    max-width: 460px;

    .input-view {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      input {
        width: 100%;
      }

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .error-label {
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors?.error};
        margin-top: 5px;
      }

      .input-info-view {
        width: 100%;
        display: flex;
        margin-top: 8px;

        span {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }
    }

    .checkbox-view {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 10px;

      .checkbox {
        margin-right: 5px;
        accent-color: ${({ theme }) => theme.colors?.secondary};
        cursor: pointer;
        width: 16px;
        height: 16px;
      }

      label {
        cursor: pointer;
      }
    }

    .date-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;

      .input-view {
        width: 48%;
        margin-bottom: 0px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        input {
          width: 100%;
        }

        .disabled {
          background-color: ${({ theme }) => theme.colors?.layer2};

          &::placeholder {
            color: ${({ theme }) => theme.colors?.info};
          }
        }
      }
    }

    .vendor {
      margin-bottom: 10px;
    }

    .add-view {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      button {
        color: ${({ theme }) => theme.colors?.secondary};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          margin-right: 10px;
        }
      }
    }

    .select-view {
      width: 100%;
      margin-bottom: 20px;

      label {
        font-size: 0.875rem;
        font-weight: 400;

        span {
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors?.info};
        }
      }

      .options {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .option {
          width: 33.333%;
          display: flex;
          align-items: center;
          cursor: pointer;

          .radio-button {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors?.primaryFocus};
            margin-right: 10px;

            .dot {
              width: 18px;
              height: 18px;
              border-radius: 50%;
              background-color: ${({ theme }) => theme.colors?.primary};
            }
          }

          p {
            font-size: 0.875rem;
            font-weight: 400;
            color: ${({ theme }) => theme.colors?.secondary};
          }
        }
      }
    }

    .cta-view {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 50px;

      button {
        width: 49%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 16px;
      }

      .cta-view__save {
        background-color: ${({ theme }) => theme.colors?.primary};
        color: ${({ theme }) => theme.colors?.white};
        margin-bottom: 20px;
        width: 69%;
      }

      .cta-view__draft {
        background-color: ${({ theme }) => theme.colors?.white};
        color: ${({ theme }) => theme.colors?.primary};
        border: 1px solid ${({ theme }) => theme.colors?.primary};
        width: 29%;
      }
    }
  }
`;
